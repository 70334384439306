<template>
  <div class='review-info'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        审核信息
        <div class="btn-save" @click="submit()">提交</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div>
            <div class="tip">注：审核信息提交后，后台将重新审核，请耐心等待审核结果</div>
            <div>
              <el-row class="row">
                <el-col :span="6" class="txt-right">企业名称</el-col>
                <el-col :span="18">
                  <el-input placeholder="请输入企业名称" v-model="companyInfo.company_name"></el-input>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="6" class="txt-right">联系人姓名</el-col>
                <el-col :span="18">
                  <el-input placeholder="请输入联系人姓名" v-model="companyInfo.contact_name"></el-input>
                </el-col>
              </el-row>
              <el-row class="row align-start">
                <el-col :span="6" class="txt-right">营业执照</el-col>
                <el-col :span="18">
                  <div>
                    <el-upload
                    class="mb20"
                    :action="action"
                    :file-list="imgs"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :on-success="uploadSuccess"
                    :limit="9"
                    :on-exceed="imgExceed">
                    <i class="el-icon-plus"></i>
                    </el-upload>
                  </div>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="6" class="txt-right">审核结果</el-col>
                <el-col :span="18" class="txt-blue">{{ companyInfo.status | checkStatus }}</el-col>
              </el-row>
              <el-row class="row" v-if="companyInfo.status==2">
                <el-col :span="6" class="txt-right">未通过原因</el-col>
                <el-col :span="18" class="txt-blue">{{ companyInfo.reson }}</el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { getCompanyCheckData, updateCompanyCheckData } from '@/api/company'

export default {
  data() {
    return {
      action: 'https://app.digginggoldroad.com/api/common/upload',
      dialogImageUrl: '',
      dialogVisible: false,
      imgs: [],
      companyInfo: {}
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    handleRemove(file, fileList) {
      this.imgs = this.imgs.filter(item=>{
        return item.url != file.url
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    imgExceed (files, fileList) {
      this.$message.warning(`最多上传9张图片`)
    },
    uploadSuccess(response, file, fileList) {
      let obj = {
        uid: file.uid,
        url: response.data.fullurl,
        uploadUrl: response.data.url
      }
      this.imgs.push(obj)
    },
    urls() {
      let arr = []
      if(this.companyInfo.license_images && this.companyInfo.license_images.length) {
        let arrStr = this.companyInfo.license_images.split(',')
        for(let i = 0; i < arrStr.length; i++) {
          let obj = {
            uid: i,
            url: this.$store.getters.imgeCDN + arrStr[i],
            uploadUrl: arrStr[i]
          }
          arr.push(obj)
        }
      }
      return arr
    },
    getInfo() {
      getCompanyCheckData().then(res=>{
        if(res.code == 1) {
          this.companyInfo = res.data
          this.imgs = this.urls()
        }
      })
    },
    submit() {
      if(!this.companyInfo.company_name) {
        this.$message.warning('请输入企业名称')
        return
      }
      if(!this.companyInfo.contact_name) {
        this.$message.warning('请输入联系人姓名')
        return
      }
      if(this.imgs.length == 0) {
        this.$message.warning('请上传营业执照')
        return
      }
      let urls = ''
      for(let i = 0; i < this.imgs.length; i++){
        urls += this.imgs[i].uploadUrl
        urls += ','
      }
      urls = urls.slice(0, -1);

      let params = {
        company_name: this.companyInfo.company_name,
        contact_name: this.companyInfo.contact_name,
        license_images: urls
      }
      updateCompanyCheckData(params).then(res=>{
        if(res.code == 1) {
          this.$message.success('已提交后台，等待审核')
          this.getInfo()
        }
      })
    }
  },
  created() {
    this.getInfo()
  },
}
</script>

<style lang="less" scoped>
.review-info{
  .content {
    border-radius: 13px 13px 13px 13px;
    padding: 20px 20px 45px;
    margin-bottom: 41px;
    background: white;
    display: flex;
    justify-content: center;

    .tip {
      height: 55px;
      line-height: 55px;
      padding: 0 53px;
      margin-bottom: 33px;
      background: rgba(233, 167, 61, 0.08);
      border-radius: 7px 7px 7px 7px;
      font-size: 20px;
      color: #E9A73D;
    }

    .row {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #222222;
      margin-bottom: 20px;

      .txt-right {
        text-align: right;
        padding-right: 33px;
      }
      .txt-blue {
        color: #2676F7;
      }

      .result-img {
        width: 170px;
        height: 123px;
        border-radius: 7px 7px 7px 7px;
      }
    }
    .align-start {
      align-items: flex-start;
    }
  }

  /deep/.el-input__inner{
    height: 53px;
    line-height: 53px;
    font-size: 20px;
  }
}
</style>
