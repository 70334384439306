<template>
  <div class='compamy-profile'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        公司资料
        <div class="btn-save" @click="submit()">确认</div>
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div class="center">
            <el-row class="row">
              <el-col :span="24" class="flex">
                <div class="img-div">
                  <img class="av-img" :src="params.avImgUrl" alt="">
                  <img class="camera" src="../../../assets/images/camera.png" @click="uploadAvImage()" alt="">
                  <el-upload class="display-none" ref="upload-img" :action="action"
                    :show-file-list="false" :on-success="handleAvatarSuccess">
                  </el-upload>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">公司名称</el-col>
              <el-col :span="16">
                <el-input placeholder="请输入公司名称" v-model="params.company_name"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">融资阶段</el-col>
              <el-col :span="16">
                <el-select v-model="params.financing" placeholder="请选择">
                  <el-option v-for="item in financeOp" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">公司规模</el-col>
              <el-col :span="16">
                <el-select v-model="params.scale" placeholder="请选择">
                  <el-option v-for="item in sizeOp" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">公司所属行业</el-col>
              <el-col :span="16">
                <el-select v-model="params.company_category_id" placeholder="请选择">
                  <el-option v-for="item in industryOp" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">工作时间</el-col>
              <el-col :span="16">
                <el-input placeholder="请输入工作时间" v-model="params.works"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">休息时间</el-col>
              <el-col :span="16">
                <el-select v-model="params.rests" placeholder="请选择">
                  <el-option v-for="item in restOp" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">公司福利</el-col>
              <el-col :span="16">
                <el-select v-model="params.benefits" multiple placeholder="请选择">
                  <el-option v-for="item in benefitsOp" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">公司地址</el-col>
              <!-- <template v-if="this.params.addressName">
                <el-input class="area-width" v-model="this.params.addressName"></el-input>
                <span class="el-icon-arrow-down cursor" @click="selectHandle"></span>
              </template> -->
              <el-col :span="16">
                <el-select v-show="isShowAddress" v-model="params.addressName" @focus="seleAddress" placeholder="请选择工作地点">
                </el-select>
                <!-- 省市县组件 start -->
                <areaProvince ref="area" v-show="isShowArea" class="area-width" @handleChangeProp="handleChange"></areaProvince>
                <!-- 省市县组件 end -->
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">详细地址</el-col>
              <el-col :span="16">
                <el-input placeholder="请输入详细地址" v-model="params.address"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">公司全称</el-col>
              <el-col :span="16">
                <el-input placeholder="请输入公司全称" v-model="params.company_full_name"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">法人名称</el-col>
              <el-col :span="16">
                <el-input placeholder="请输入法人名称" v-model="params.legal_person"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">注册资本</el-col>
              <el-col :span="16">
                <el-input placeholder="请输入注册资本" v-model="params.registered_capital"></el-input>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="8" class="txt-right">成立日期</el-col>
              <el-col :span="16">
                <el-date-picker
                  v-model="params.establish_date"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
              </el-col>
            </el-row>
            <el-row class="row flex-start">
              <el-col :span="8" class="txt-right">在职感受群聊二维码</el-col>
              <el-col :span="16">
                <el-upload
                class="qrcode-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="handleQrcodeSuccess">
                <img v-if="params.qrcodeImgUrl" :src="params.qrcodeImgUrl" class="qrcode">
                <i v-else class="el-icon-plus qrcode-uploader-icon"></i>
                </el-upload>
              </el-col>
            </el-row>
            <el-row class="row flex-start">
              <el-col :span="8" class="txt-right">公司介绍</el-col>
              <el-col :span="16">
                <el-input
                type="textarea"
                :autosize="{ minRows: 5}"
                placeholder="请输入公司介绍"
                v-model="params.desc">
              </el-input>
              </el-col>
            </el-row>
            <el-row class="row flex-start">
              <el-col :span="8" class="txt-right">公司相册</el-col>
              <el-col :span="16">
                <el-upload
                  class="mb20"
                  :action="action"
                  list-type="picture-card"
                  :file-list="fileList"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :on-success="(response, file)=>uploadSuccess(response, file,)"
                  :limit="9"
                  :on-exceed="imgExceed">
                  <i class="el-icon-plus"></i>
                  </el-upload>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import { userInfo, companyGetConfig, companyProfile } from '@/api/userInfo'
import { companyDetail } from '@/api/jobSeeker'
import areaProvince from '@/components/area.vue'
export default {
  components: {
    areaProvince
  },
  data() {
    return {
      action: 'https://app.digginggoldroad.com/api/common/upload',
      dialogImageUrl: '',
      dialogVisible: false,
      financeOp: [], // 融资
      sizeOp: [], // 规模
      industryOp: [], // 公司类型
      restOp: [],
      benefitsOp: [],
      params: {
        avImgUrl: require('@/assets/images/av-test.png'),
        avatar: '',
        company_name: '',
        financing: '',
        scale: '',
        company_category_id: '',
        works: '',
        rests: '',
        benefits: [],
        addressArr: [],
        address: '',
        company_full_name: '',
        legal_person: '',
        registered_capital: '',
        establish_date: '',//成立日期
        qrcodeImgUrl: '',
        group_qrcode_image: '',
        desc: '',
        companyId: '', // 公司id
        addressId: [],
        addressName: ''
      },
      fileList: [],
      company_city: [],
      isShowAddress: true,
      isShowArea: false,
    }
  },
  created () {
    this.companyId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).company_id : ''
    this.companyGetConfig()
    this.getCompanyDetail()
  },
  methods: {
    seleAddress() {
      this.$refs.area.selectArea()

      this.isShowAddress = false
      this.isShowArea = true
    },
    /**
     * @Author: angel~dongsanling
     * @description: 选择城市
     * @param {*} data
     */
    handleChange(data){
      // this.work_city = data.length && data.length > 1 ? data[1] : data[0]
      this.company_city = data
    },
    /**
     * @Author: angel~dongsanling
     * @description: 公司详情
     */
    getCompanyDetail() {
      let params = {
        id: this.companyId
      }
      this.params.benefits = []
      companyDetail(params).then(res=>{
        if(res.code == 1) {
          Object.keys(this.params).forEach(key => {
            this.params[key] = res.data[key]
          })
          this.params.benefits = res.data.welfare_text
          this.params.qrcodeImgUrl = res.data.group_qrcode_image ? `${this.$store.getters.imgeCDN}${res.data.group_qrcode_image}` : ''
          this.params.avImgUrl = res.data.avatar ? `${this.$store.getters.imgeCDN}${res.data.avatar}` : ''
          this.params.addressName = res.data.area.mergename ? res.data.area.mergename : ''
          this.params.addressId = [String(res.data.area.province),String(res.data.area.city),String(res.data.area.area)]
          this.company_city = JSON.parse(JSON.stringify(this.params.addressId))
          this.value = JSON.parse(JSON.stringify(this.params.addressId))

          this.params.benefits = res.data.welfare ? res.data.welfare.split(',') : []
          if (res.data.images) {
            res.data.images.split(',').map((item,index) => { // 公司相册
              this.fileList.push({
                name: `photo${index + 1}.jpg`,
                url: item ? `${this.$store.getters.imgeCDN}${item}` : '',
                uploadUrl: item ? `${item}` : '',
              })
            })
          }
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 枚举所有数据
     */
    companyGetConfig () {
      this.financeOp = []
      this.sizeOp = []
      this.industryOp = []
      this.restOp = []
      this.benefitsOp = []
      companyGetConfig({type: 'all'}).then(res=>{
        if (res.code == 1) {
          let data = res.data
          if(data.financing){ // 融资
            for (const key in data.financing) {
              this.financeOp.push({
                label: data.financing[key],
                value: Number(key)
              })
            }
          }
          if (data.scale) { // 规模
            for (const key in data.scale) {
              this.sizeOp.push({
                label: data.scale[key],
                value: Number(key)
              })
            }
          }
          if (data.company_category) { // 公司类型
            for (const key in data.company_category) {
              this.industryOp.push({
                label: data.company_category[key],
                value: Number(key)
              })
            }
          }
          if (data.rest_time) { // 休息时间
            for (const key in data.rest_time) {
              this.restOp.push({
                label: data.rest_time[key],
                value: Number(key)
              })
            }
          }
          if (data.welfare) { // 公司福利
            for (const key in data.welfare) {
              this.benefitsOp.push({
                label: data.welfare[key],
                value: key
              })
            }
          }
        }
      })
    },
    back() {
      this.$router.back()
    },
    uploadAvImage() {
      this.$refs['upload-img'].$refs['upload-inner'].handleClick()
    },
    handleAvatarSuccess(res, file) {
      this.params.avImgUrl = URL.createObjectURL(file.raw);
    },
    handleQrcodeSuccess(res, file) {
      this.params.qrcodeImgUrl = URL.createObjectURL(file.raw);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.fileList = this.fileList.filter(item=>{
        return item.url != file.url
      })
    },
    imgExceed (files, fileList) {
      this.$message.warning(`最多上传9张图片`)
    },
    uploadSuccess(response, file) {
      this.fileList.push({
        uploadUrl: file.response.data.url,
        url: file.response.data.fullurl,
        name: file.name,
      })
    },
    submit() {
      let params = JSON.parse(JSON.stringify(this.params))
      let images = []
      params.images = ''
      delete params.benefits
      delete params.addressId
      delete params.addressName
      params.welfare = this.params.benefits.length ? this.params.benefits.join(',') : ''
      if (this.fileList.length) {
        this.fileList.map((item,index) => {
          images.push(item.uploadUrl)
        })
      }
      params.images = images.length ? images.join(',') : ''
      params.area_id = this.company_city[this.company_city.length - 1]
      companyProfile(params).then(res => {
        if (res.code == 1) {
          this.$message.success('操作成功')
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.compamy-profile {
  .content {
    border-radius: 13px 13px 13px 13px;
    padding: 20px 20px 45px;
    margin-bottom: 41px;
    background: white;
    display: flex;
    justify-content: center;

    .center {
      width: 60%;
      .row {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #222222;
        margin-bottom: 20px;

        .flex {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .area-width{
          width: 464px !important;
          &.el-input{
            margin-right: 0px !important;
          }
        }
        .el-icon-arrow-down{
          position: absolute;
          right: 11px;
          font-size: 15px;
          color: #ccc;
        }
        .img-div {
          position: relative;

          .av-img {
            width: 100px;
            height: 100px;
            border-radius: 50px;
          }

          .camera {
            width: 40px;
            height: 40px;
            position: absolute;
            bottom: 0;
            right: -18px;
            cursor: pointer;
          }
        }

        .txt-right {
          text-align: right;
          padding-right: 33px;
        }

        .flex-start {
          align-items: flex-start;
        }

        .display-none {
          display: none;
        }

        .qrcode-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }
        .qrcode-uploader .el-upload:hover {
          border-color: #409EFF;
        }
        .qrcode-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 123px;
          height: 123px;
          line-height: 123px;
          text-align: center;
          border: 1px solid #E8E8E8;
        }
        .qrcode {
          width: 123px;
          height: 123px;
          display: block;
        }
      }
    }
  }

  /deep/.el-input__inner {
    height: 53px;
    line-height: 53px;
    font-size: 20px;
  }

  /deep/.el-select {
    width: 100%;
  }

  /deep/.el-select>.el-input {
    margin-right: 0;
  }
  /deep/.el-tag.el-tag--info{
    font-size: 20px;
  }
  /deep/.el-cascader{
    width: 100%;
  }
  /deep/.el-date-editor.el-input{
    width: 100%;
  }
  /deep/.el-input__prefix{
    font-size: 20px;
  }
  /deep/.el-textarea__inner{
    font-size: 20px;
  }
  /deep/.el-upload--picture-card{
    width: 123px;
    height: 123px;
    line-height: 123px;
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item{
    width: 123px;
    height: 123px;
  }
}
</style>
<style>
.el-cascader-node__label{
  font-size: 20px;
}
</style>
