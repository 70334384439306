<template>
  <div class='my-order'>
    <div class="page-header">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        我的订单
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="tab-div">
          <div class="tab-item" :class="{'active': index == activeIndex}" v-for="(item, index) in tabs" :key="index" @click="handleTabItem(item)">{{ item.title }}</div>
        </div>
        <div class="data-item" v-for="(item, index) in dataList" :key="index">
          <img :src="imgUrl(item.cover_image)" alt="">
          <div class="center">
            <div class="title">{{ item.title }}</div>
            <div class="name">{{ item.nickname }}</div>
            <div class="time">{{ item.createtime | toBlockTime }}</div>
          </div>
          <div class="right">
            <div v-if="item.status == 2">
              <div class="status-blue">已完成</div>
              <div class="amount">￥{{ item.amount }}</div>
            </div>
            <div v-else >
              <div class="status-red">待付款</div>
              <div class="opera">
                <div class="amount width-auto">￥{{ item.amount }}</div> <div class="pay-btn" @click="payNow(item)">去付款</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderList } from '@/api/jobSeeker'
import { userInfo } from '@/api/userInfo'

export default {
  data() {
    return {
      activeIndex: 0,
      params: {
        limit: 10,
        page: 1,
        status: 0
      },
      tabs: [
        {
          title: '全部',
          index: 0,
        },{
          title: '待付款',
          index: 1,
        },{
          title: '已完成',
          index: 2,
        },
      ],
      dataList: []
    }
  },
  methods: {
    back(){
      this.$router.back()
    },
    initUserInfo() {
      userInfo().then(res=>{
        if(res.code == 1) {
          this.userInfo = res.data.userinfo
        }
      })
    },
    handleTabItem(item) {
      this.activeIndex = item.index
      this.params.status = this.activeIndex
      this.params.page = 1
      this.getOrderList()
    },
    getOrderList() {
      orderList(this.params).then(res=>{
        if(res.code == 1) {
          this.dataList = res.data
        }
      })
    },
    payNow(item) {
      let url = `https://app.digginggoldroad.com/api/jobseeker/order?lesson_id=${item.lesson_id}&method=web&user_id=${this.userInfo.id}`
      window.open(url)
    }
  },
  created() {
    this.initUserInfo()
    this.getOrderList()
  },
  computed: {
    imgUrl: function() {
      return function(img) {
        if(img) {
          return this.$store.getters.imgeCDN + img
        }
        return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.my-order {
  .tab-div{
    width: 100%;
    height: 92px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    margin: 20px 0;
    padding: 0 197px 0 117px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tab-item{
      font-size: 23px;
      color: #222222;
      cursor: pointer;
    }

    .active{
      color: #2676F7;
    }
  }

  .data-item{
    width: 100%;
    padding: 33px 40px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 13px;
    display: flex;
    justify-content: space-between;

    img{
      width: 107px;
      height: 107px;
      margin-right: 33px;
      border-radius: 50%;
    }

    .center {
      width: 70%;
      // background-color: red;
      font-size: 20px;
      color: #999999;
      .title {
        font-weight: 500;
        font-size: 23px;
        color: #222222;
      }

      .name {
        margin: 10px 0;
      }
    }

    .right {
      width: 18%;
      // background: darkcyan;
      text-align: center;
      .status-blue{
        font-weight: 500;
        color: #2676F7;
        font-size: 23px;
        margin-bottom: 30px;
        width: 100%;
        text-align: right;
      }

      .status-red{
        width: 100%;
        text-align: right;
        font-weight: 500;
        color: #E11E34;
        font-size: 23px;
        margin-bottom: 30px;
      }

      .amount{
        font-weight: 600;
        font-size: 23px;
        color: #E11E34;
        width: 100%;
        text-align: right;
      }

      .opera{
        display: flex;
        align-items: center;
        .pay-btn{
          width: 127px;
          height: 42px;
          line-height: 42px;
          margin-left: 20px;
          background: #2676F7;
          border-radius: 7px 7px 7px 7px;
          font-weight: 500;
          font-size: 17px;
          color: #FFFFFF;
          text-align: center;
          cursor: pointer;
          display: inline-block;
        }

        .width-auto {
          width: auto;
        }
      }

    }
  }
}

</style>
