<template>
  <div class='confirm-order'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="detail">
          <div class="address">
            <addressItem v-for="(item,index) in addressList" :key="index" :item="item" :index="index" @handelSelectAddress="handelSelectAddress"></addressItem>
          </div>
          <div class="shop-header">
            <div class="shop-header-item width400">
              商品
            </div>
            <div class="shop-header-item width200">
              数量
            </div>
            <div class="shop-header-item width200">
              单价
            </div>
            <div class="shop-header-item width200">
              规格
            </div>
            <div class="shop-header-item width150">
              小计
            </div>
          </div>
          <div class="shop-content" v-for="(item, index) in tableData" :key="index">
            <div class="shop-content-name">{{ item.shop_name }}</div>
            <div class="shop-content-box" v-for="(subItem, subIndex) in item.products" :key="subIndex">
              <div class="shop-content-box-item flex flex-start width400">
                <img class="goods-img" :src="subItem.goodsImg" alt="">
                <div class="font-weight500">{{ subItem.title }}</div>
              </div>
              <div class="shop-content-box-item width200">
                {{ subItem.number }}
              </div>
              <div class="shop-content-box-item width200">
                {{ subItem.sku.price }}
              </div>
              <div class="shop-content-box-item width200">
                {{ subItem.sku.difference.join(',') }}
              </div>
              <div class="shop-content-box-item width150">
                {{ Number(subItem.sku.price * subItem.number).toFixed(2) }}
              </div>
            </div>
            <el-row class="row" v-if="tableData && tableData[0].shop_id != 3">
              <el-col :span="2" class="font-16-333">优惠折扣：</el-col>
              <el-col :span="22" class="flex flex-start font-16-999">
                <div class="select-coupon" v-if="!item.selectedCoupon" 
                  @click="handleShowSelect('select',item,index)">
                  请选择 
                  <img class="more-img" src="../../../assets/images/arrow-right.png" alt="">
                </div>  
                <div v-else @click="item.isShowSelectCouponDialog=true">
                  <coupon :item="item.selectedCoupon" :btn="false"></coupon>
                </div>
                
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="2" class="font-18-58">快递运费</el-col>
              <el-col :span="22" class="font-18-222">
                {{ item.freight.name + '￥' + item.freight.price }}
              </el-col>
            </el-row>
            <el-row class="row" v-if="tableData && tableData[0].shop_id == 3">
              <el-col :span="2" class="font-18-58">积分抵扣</el-col>
              <el-col :span="22" class="font-18-222">
                共{{ userData.score }}积分，
                <span class="font-18-red">可抵扣 {{ '￥' + userData.score/10 }}</span>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="2" class="font-18-58">总计</el-col>
              <el-col :span="22"> 
                <span class="font-18-222">共{{ item.number }}件，小计：</span> 
                <span class="font-18-red">
                  <!-- <span class="font-18-red" v-if="item.selectedCoupon"> -->
                  <!-- {{ type ? (item.sub_price - item.selectedCoupon.price) > 0 ? (item.sub_price - item.selectedCoupon.price) : 0 : (item.sub_price  - item.selectedCoupon.price) > 0 ? (item.sub_price  - item.selectedCoupon.price) : 0 + '+积分344' }} -->
                  <!-- {{ type ? item.subMoney : item.subMoney + '+积分' }} -->
                  {{ item.subMoney }}
                </span>
                <!-- <span class="font-18-red" v-else> -->
                  <!-- {{ type ? item.sub_price : item.sub_price + '+积分344' }} -->
                  <!-- {{ item.subMoney }}
                </span> -->
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="2" class="font-18-58">备注</el-col>
              <el-col :span="6" class="font-18-666">
                <el-input placeholder="订单备注可选" v-model="remarks"></el-input>
              </el-col>
            </el-row>
            <selectCouponDialog v-if="item.isShowSelectCouponDialog"  @handleReceive="handleReceive" :coupons="item.couponArray" @selectCoupon="selectCoupon"></selectCouponDialog>
          </div>
        </div>
        <div class="bottom">
          <div class="amount-to">
            <div class="top">
              <div class="font-22-333">共{{ statis.allnum }}件，合计：</div>
              <div class="font-22-red" v-if="tableData && tableData[0] && tableData[0].shop_id == 3">￥{{ userData.score/10 > statis.allsub ? 0.01 : statis.allsub - userData.score/10 }}</div>
              <div class="font-22-red" v-if="tableData && tableData[0] && tableData[0].shop_id != 3">￥{{ statis.allsub }}</div>  
              <div class="font-18-red" v-if="tableData && tableData[0] && tableData[0].shop_id == 3">+积分 {{ userData.score/10 > statis.allsub ? statis.allsub * 10 : userData.score }}</div>
            </div>
            <!-- <div>不含运费</div> -->
          </div>
          <div class="sub-order" @click="submitOrder()">提交订单</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import addressItem from '@/components/shoppingMall/addressItem.vue';
import selectCouponDialog from '@/components/shoppingMall/selectCouponDialog.vue';
import coupon from '@/components/shoppingMall/coupon.vue';
import { wanlshopGetOrderGoodsList, wanlshopGetaddress, wanlshopQuery, wanlshopAddOrder, wanlshopCartStorage, wanlshopPayment } from '../../../api/shoppingMall'

export default {
  components: {
    crumbsVue,
    addressItem,
    selectCouponDialog,
    coupon,
  },
  data() {
    return {
      // navType: 1, //1订单结算  2确认订单
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '确认订单',
        },
      ],
      addressList: [],
      isShowSelectCouponDialog: false,
      selectedAddress: null, //当前选择的地址对象
      tableData: [],
      couponArray: [{//优惠券备选数组
        price: 10,
        fullUse: 50,
        beforeUse: '未使用前 永久有效',
        useNum: 2,
      },{
        price: 10,
        fullUse: 20,
        beforeUse: '未使用前 永久有效',
        useNum: 2,
      },{
        price: 10,
        fullUse: 40,
        beforeUse: '未使用前 永久有效',
        useNum: 2,
      },{
        price: 10,
        fullUse: 70,
        beforeUse: '未使用前 永久有效',
        useNum: 2,
      },{
        price: 10,
        fullUse: 150,
        beforeUse: '未使用前 永久有效',
        useNum: 2,
      }],
      selectedCoupon: null, //当前选择的优惠券
      remarks: '',//备注
      params: {},
      type: '', // 根据类型判断是积分还是商品
      statis: {}, // 总计金额
      currentIndex: '',
      currentAddress: {}, // 当前默认地址
      userData: {}, // 用户数据
      currentToken: '', // 下订单返回的参数
      source: '', // 默认来源是详情直接购买
      cartSelectedArr: []
    }
  },
  created () {
    this.navs[1].title = this.$route.query.navType ? this.$route.query.navType : '确认订单'
    this.type = this.$route.query.navType ? this.$route.query.navType : ''
    this.params = this.$route.query.data ? JSON.parse(this.$route.query.data) : []
    this.source = this.$route.query.source ? this.$route.query.source : ''
    this.cartSelectedArr = this.$route.query.selectedArr ? JSON.parse(this.$route.query.selectedArr) : []
    this.getWanlshopGetaddress()
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 更新购物车
     */    
    getWanlshoapCartStorage (params) {
      let data = []
      if (this.source && this.source == 'cart') {
        data = this.cartSelectedArr
      } else {
        data = []
      }
      wanlshopCartStorage({ data: data, type: 'del' }).then(res => {
        if (res.code == 1) {
          this.getWanlshopPayment(params)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 支付订单
     * @param {*} id 订单id
     */    
    getWanlshopPayment (params) {
      let ids = []
      if (params && params.list.length) {
        params.list.map((item, index) => {
          if (item.id) {
            ids.push(item.id)
          }
        })
      }
      wanlshopPayment({ method: 'web', type: 'wechat', order_type: 'goods', order_id: ids.join(','),token: params && params.token }).then(res => {
        if (res.code == 1) {
          window.open(res.data, '_blank')
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 
     */    
    /**
     * @Author: angel~dongsanling
     * @description: 选择优惠券
     * @param {*} type 根据类型判断是否有值
     * @param {*} data 当前数据
     * @param {*} index 当前的下标
     */    
    handleShowSelect (type, data,index) {
      this.currentIndex = index
      if (type == 'select') {
        this.tableData[index].isShowSelectCouponDialog = true
        let params = {
          price: data.sub_price,
          shop_id: data.shop_id
        }
        let ids = []
        if (data?.products?.length) {
          data.products.map((item, index) => {
            ids.push(item.id)
            params.shop_category_id = item.shop_category_id
          })
          params.goods_id = ids.join(',')
        }
        this.getWanlshopQuery(params,data)
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 提交订单
     */    
    createWanlshopAddOrder () {
      let lists = []
      if (this.tableData.length) {
        this.tableData.map((item, index) => {
          item.productsNew = []
          if (item.products.length) {
            item.products.map((subItem, subIndex) => {
              item.productsNew.push({
                freight_id: subItem.freight_id,
                goods_id: subItem.sku.goods_id,
                number: subItem.number,
                sku_id: subItem.sku.id
              })
            })
          }
          lists.push({
            coupon_id: item.selectedCoupon ? item.selectedCoupon.id : 0,
            products: item.productsNew,
            shop_id: item.shop_id
          })
        })
      }
      let params = {
        address_id: this.currentAddress.id,
        lists: lists
      }
      wanlshopAddOrder({ order: params, token: this.currentToken }).then(res => {
        if (res.code == 1) {
          this.getWanlshoapCartStorage(res.data)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 优惠券列表
     */    
    getWanlshopQuery (params, currentData) {
      let couponArray = []
      wanlshopQuery(params).then(res => {
        if (res.code == 1) {
          if (res.data.length) {
            res.data.map((item, index) => {
              couponArray.push({
                price: item.price,
                fullUse: item.name,
                beforeUse: item.pretype_text,
                useNum: item.limit,
                drawlimit: item.limit,
                ...item,
                shop: {
                  shopname: currentData.shop_name
                }
              })
            })
          }
          if (this.tableData.length) {
            this.tableData.map((item, index) => {
              if (item.shop_id == currentData.shop_id) {
                item.couponArray = couponArray
              }
            })
          }
          this.$forceUpdate()
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 地址列表
     */    
    getWanlshopGetaddress () {
      this.addressList = []
      wanlshopGetaddress().then(res => {
        if (res.code == 1) {
          if (res.data.data.length) {
            res.data.data.map((item, index) => {
              this.addressList.push({
                isSelected: false,
                isDefault: false,
                ...item
              })
            })
            this.getWanlshopGetOrderGoodsList()
          } else {
            this.$router.push('address')
          }
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取订单详情
     */    
    getWanlshopGetOrderGoodsList () {
      wanlshopGetOrderGoodsList({ data: this.params }).then(res => {
        if (res.code == 1) {
          if (res.data.addressData) {
            if (this.addressList.length) {
              this.addressList.map((item, index) => {
                if (item.id === res.data.addressData.id) {
                  item.isSelected = true
                  item.isDefault = true
                }
              })
            }
          }
          if (res.data.orderData.lists.length) {
            res.data.orderData.lists.map((item, index) => {
              item.subMoney = item.sub_price
              if (item.products.length) {
                item.products.map((subItem, subIndex) => {
                  subItem.goodsImg = `${this.$store.getters.imgeCDN}${subItem.image}`
                })
              }
              item.couponArray = []
              item.isShowSelectCouponDialog = false
            })
          }
          this.tableData = res.data.orderData.lists
          this.statis = res.data.orderData.statis
          this.currentAddress = res.data.addressData
          this.userData = res.data.userData
          this.currentToken = res.data.token
        }
      })
    },
    // 地址是否选择切换
    handelSelectAddress(item, index) {
      this.addressList.map(obj=>{
        obj.isSelected = false
      })
      this.addressList[index].isSelected = !this.addressList[index].isSelected
      if(this.addressList[index].isSelected) {
        this.selectedAddress = item
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 
     */    
    handleReceive (data) {
      this.tableData[this.currentIndex].selectedCoupon = data
      this.tableData[this.currentIndex].isShowSelectCouponDialog = false
      this.tableData[this.currentIndex].subMoney = this.tableData[this.currentIndex].sub_price - item.price > 0 ? this.tableData[this.currentIndex].sub_price - item.price : 0
      this.statis.allsub = this.tableData[this.currentIndex].sub_price - item.price > 0 ? this.statis.allsub - item.price : this.statis.allsub - this.tableData[this.currentIndex].sub_price
    },
    // 选择了某个优惠券
    selectCoupon(item) {
      this.tableData[this.currentIndex].selectedCoupon = item
      this.tableData[this.currentIndex].isShowSelectCouponDialog = false
      this.tableData[this.currentIndex].subMoney = this.tableData[this.currentIndex].sub_price - item.price > 0 ? this.tableData[this.currentIndex].sub_price - item.price : 0
      this.statis.allsub = this.tableData[this.currentIndex].sub_price - item.price > 0 ? this.statis.allsub - item.price : this.statis.allsub - this.tableData[this.currentIndex].sub_price
    },
    submitOrder() {
      this.createWanlshopAddOrder()
    }
  }
}
</script>

<style lang="less" scoped>
.confirm-order {
  .detail{
    padding: 30px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    margin-bottom: 20px;
    .width400{
      width: 400px;
    }
    .width200{
      width: 200px;
    }
    .width150{
      width: 150px;
    }
    .address {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 31px;
    }

    .font-weight500 {
      font-weight: 500;
    }
    .flex{
      display: flex;
      align-items: center;
      .goods-img{
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      .more-img{
        width: 20px;
        height: 20px;
      }
    }
    .flex-start {
      justify-content: flex-start;
    }
    .padding15{
      padding: 15px 0;
    }
    .row{
      margin-top: 20px;
      display: flex;
      align-items: center;
      .font-16-333{
        font-size: 16px;
        color: #333333;
      }
      .font-16-999{
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
      .font-18-58{
        font-size: 18px;
        color: #585858;
      }
      .font-18-222{
        font-size: 18px;
        color: #222;
      }
      .font-18-666{
        font-size: 18px;
        color: #666;
      }
      .font-18-red{
        font-size: 18px;
        color: #FF1010;
      }
      .select-coupon {
        display: flex;
        align-items: center;
      }
      /deep/.el-input__inner {
        font-size: 18px;
      }
    }
    .shop-header{
      height: 48px;
      padding-left: 30px;
      display: flex;
      align-items: center;
      background: #ccc;
      .shop-header-item{
        font-size: 16px;
        line-height: 28px;
        
      }
    }
    .shop-content{
      .shop-content-name{
        font-size: 18px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 30px;
      }
      .shop-content-box{
        display: flex;
        margin-bottom: 20px;
        .shop-content-box-item{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          .goods-img{
            width: 80px;
            height: 80px;
            margin-right: 20px;
          }
          .font-weight500{
            flex: 1;
          }
        }
      }
    }
  }

  .bottom{
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 60px;

    .amount-to {
      margin-bottom: 3px;
      font-size: 18px;
      color: #999999;

      .top{
        display: flex;
        align-items: center;
      }
      .font-22-333{
        font-weight: 500;
        font-size: 22px;
        color: #333;
      }
      .font-22-red{
        font-weight: 500;
        font-size: 22px;
        color: #FF1B1B;
      }
      .font-18-red {
        font-weight: 500;
        font-size: 18px;
        color: #FF1B1B;
      }
    }
    .sub-order {
      width: 112px;
      height: 40px;
      line-height: 40px;
      margin-left: 28px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      background: #2676F7;
      border-radius: 20px;
      cursor: pointer;
    }
  }

  /deep/.el-table th.el-table__cell.is-leaf{
    text-align: center;
    border-bottom: 1px solid #ECECEC;
    font-size: 18px;
    color: #666666;
  }
  /deep/.el-table th.el-table__cell {
    background: #FAFAFA;
  }
  /deep/.el-table--enable-row-transition .el-table__body td.el-table__cell{
    text-align: center;
    font-size: 16px;
    color: #333;
  }
  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
    background: #fff;
  }
}
</style>