<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-15 15:28:42
 * @Description: 个人资料
-->
<template>
  <div class='personal-data'>
    <div class=" page-header">
      <div class="page-header-content ">
        <span class="back el-icon-setting cursor" @click="back()">返回</span>
        个人资料
        <div class="btn-save cursor" @click="operate('save')">保存更改</div>
      </div>
    </div>
    <div class="personal-data-main width1200">
      <div class="personal-data-main-header">
        <img :src="ruleForm.avatarShow" alt="">
        <img class="camera" src="../../../assets/images/camera.png" @click="uploadAvImage()" alt="">
        <el-upload
          class="display-none"
          ref="upload-img"
          :action="action"
          :show-file-list="false"
          :on-success="(response, file)=>handleAvatarSuccess(response, file,)">
        </el-upload>
      </div>
      <div class="personal-data-main-content">
        <div class="personal-data-main-content-item">
          <div class="personal-data-main-content-item-box flex">
            <div class="personal-data-main-content-item-label">用户名</div>
            <el-input v-model="ruleForm.nickname"
              class="personal-data-main-content-item-value"
              placeholder="请输入用户名">
            </el-input>
          </div>
          <div class="personal-data-main-content-item-box">
            <div class="personal-data-main-content-item-label">性别</div>
            <el-radio-group v-model="ruleForm.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </div>
          <div class="personal-data-main-content-item-box">
            <div class="personal-data-main-content-item-label">参加工作时间</div>
            <el-date-picker
              v-model="ruleForm.work_time"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="personal-data-main-content-item-box">
            <div class="personal-data-main-content-item-label">手机号</div>
            <el-input v-model="ruleForm.mobile"
              class="personal-data-main-content-item-value"
              placeholder="请输入用户名">
            </el-input>
          </div>
          <div class="personal-data-main-content-item-box">
            <div class="personal-data-main-content-item-label">微信号</div>
            <el-input v-model="ruleForm.wechat"
              class="personal-data-main-content-item-value"
              placeholder="请输入用户名">
            </el-input>
          </div>
          <div class="personal-data-main-content-item-box">
            <div class="personal-data-main-content-item-label">出生年月</div>
            <el-date-picker
              v-model="ruleForm.birthday"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </div>
          <div class="personal-data-main-content-item-box">
            <div class="personal-data-main-content-item-label">最高学历</div>
            <el-select class="personal-data-main-content-item-value" v-model="ruleForm.education" placeholder="请选择">
              <el-option
                v-for="item in educationArr"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="personal-data-main-content-item-box">
            <div class="personal-data-main-content-item-label">求职状态</div>
            <el-select class="personal-data-main-content-item-value" v-model="ruleForm.job_status" placeholder="请选择">
              <el-option
                v-for="item in jobStatusArr"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { companyGetConfig, userProfile, userInfo  } from '@/api/userInfo'
export default {
  name: 'personalData',
  data() {
    return {
      action: 'https://app.digginggoldroad.com/api/common/upload',
      ruleForm: {
        avatar: '',
        nickname: '',
        gender: '',
        work_time: '',
        mobile: '',
        wechat: '',
        birthday: '',
        education: '',
        job_status: '',
        avatarShow: ''
      },
      jobStatusArr: [{
        label: '离职-随时到岗',
        value: '1'
      },{
        label: '在职-月内到岗',
        value: '2'
      },{
        label: '在职-考虑机会',
        value: '3'
      },{
        label: '在职-暂不考虑',
        value: '4'
      }],
      educationArr: [],
      imgeCDN: this.$store.getters.imgeCDN
    }
  },
  created () {
    this.getUserInfo() // 用户信息
    this.companyGetConfigList() // 获取学历列表
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 用户信息
     */
    getUserInfo () {
      userInfo().then(res => {
        if (res.code == 1){
          let data = res.data.userinfo
          this.ruleForm.avatar = data.avatar ? data.avatar: ''
          this.ruleForm.nickname = data.nickname ? data.nickname: ''
          this.ruleForm.gender = data.gender ? Number(data.gender) : ''
          this.ruleForm.work_time = data.work_time ? data.work_time : ''
          this.ruleForm.mobile = data.mobile ? data.mobile : ''
          this.ruleForm.wechat = data.wechat ? data.wechat : ''
          this.ruleForm.birthday = data.birthday ? data.birthday : ''
          this.ruleForm.education = data.education ? String(data.education) : ''
          this.ruleForm.job_status = data.job_status ? String(data.job_status) : ''
          this.ruleForm.avatarShow = this.imgeCDN + data.avatar
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取学历列表
     */
    companyGetConfigList(){
      this.educationArr = []
      companyGetConfig({type: 'education'}).then(res => {
        if (res.code == 1){
          let data = res.data.education
          if (data) {
            for (const key in data) {
              this.educationArr.push({
                label: data[key],
                value: key
              })
            }
          }
        }
      })
    },
    back() {
      this.$router.back()
    },
    /**
     * @Author: angel~dongsanling
     * @description: 操作
     * @param {*} type 根据类型判断是那个方法
     * @param {*} data 当前点击传的参数
     */
    operate (type, data) {
      switch (type) {
        case 'save': // 保存修改
          let params = JSON.parse(JSON.stringify(this.ruleForm))
          delete params.avatarShow
          userProfile(params).then(res => {
            if (res.code == 1) {
              this.$message.success('修改成功')
              this.back()
            } else {
              this.$message.error(res.msg)
            }
          })
          break
      }
    },
    uploadAvImage() {
      this.$refs['upload-img'].$refs['upload-inner'].handleClick()
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.avImgUrl = URL.createObjectURL(file.raw);
      this.ruleForm.avatar = `${res.data.url}`
      this.ruleForm.avatarShow = res.data.fullurl ? res.data.fullurl : ''
    },
  }
}
</script>

<style scoped lang="less">
.personal-data{
  background: #F7F7F7;
  .personal-data-main{
    padding: 20px 24px 40px;
    margin-top: 12px;
    background: #fff;
    box-sizing: border-box;
    .personal-data-main-header{
      width: 100px;
      height: 100px;
      margin: 0 auto;
      position: relative;
      img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      .camera{
        position: absolute;
        width: 40px;
        height: 40px;
        right: -10px;
        bottom: 0;
        cursor: pointer;
      }
      .display-none {
        display: none;
      }
    }
    .personal-data-main-content{
      padding: 40px 0px 20px;
      .personal-data-main-content-item{
        text-align: center;
        .personal-data-main-content-item-box{
          width: 650px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 0 auto 15px;
          .personal-data-main-content-item-label{
            width: 120px;
            margin-right: 30px;
            text-align: right;
            display: inline-block;
          }
          .personal-data-main-content-item-value{
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
