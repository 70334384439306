<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-24 19:19:20
 * @Description: 商品详情
-->
<template>
  <div class="product-detail">
    <!-- 搜索 start -->
    <div class="product-detail-search width1200">
      <search ref="search" class="product-detail-search-box" @searchBtnClick="search"></search>
    </div>
    <!-- 搜索 end -->
    
    <!-- 面包屑 start -->
    <div class="product-detail-crumbs">
      <div class="product-detail-crumbs-box width1200">
        <crumbsVue ref="crumbsVue" :navs="navs"></crumbsVue>
      </div>
    </div>
    <!-- 面包屑 end -->
    
    <div class="product-detail-content width1200 flex space-between">
      <div class="product-detail-content-left">
        <div class="product-detail-content-left-img">
          <!-- <img :src="currentImg" alt=""> -->
          <el-image 
            :src="currentImg" 
            :preview-src-list="currentImgArr">
          </el-image>
        </div>
        <div class="product-detail-content-left-bottom flex space-between">
          <div class="product-detail-content-left-bottom-left cursor" @click="operate('lunboLeft',lunboIndex)">
            <img src="../../../assets/images/icon-arrow-left.png" alt="">
          </div>
          <div class="product-detail-content-left-bottom-center flex flex-start">
            <div class="product-detail-content-left-bottom-center-item cursor"
              :class="{'active': lunboIndex == index}"
              v-for="(item,index) in lunboArr" :key="index"
              @click="operate('lunbo',index,item)">
              <img :src="item.img" alt="">
            </div>
          </div>
          <div class="product-detail-content-left-bottom-right cursor" @click="operate('lunboRight',lunboIndex)">
            <img src="../../../assets/images/icon-arrow-right.png" alt="">
          </div>
        </div>
      </div>
      <div class="product-detail-content-right">
        <div class="product-detail-content-right-title">
          {{ productDetail.title }}
        </div>
        <div class="product-detail-content-right-price">
          <div class="product-detail-content-right-price-up">
            <span class="new-price">
              ￥{{ currentSkuSelect.price }}
            </span>
            <del class="old-price">{{ productType ? '原价' : '市场价' }}¥{{ currentSkuSelect.market_price }}</del>
          </div>
          <div class="product-detail-content-right-price-down">
            <span class="city">{{ productDetail && productDetail.shop && productDetail.shop.sendAddress }}</span>
            <span class="freight">运费：{{ productDetail && productDetail.freight && productDetail.freight.name }}</span>
            <span class="sales-num">月销：{{ productDetail.sales }}</span>
          </div>
        </div>
        <div class="product-detail-content-right-item flex space-between" v-if="couponArr.length">
          <div class="product-detail-content-right-item-left">
            <span class="title">领券</span>
            <span class="quan">{{ '¥' + couponArr[0].discount }}</span>
            <span class="full-quan">满{{ couponArr[0].validity }}
              {{ '减' +  + couponArr[0].discount }}</span>
          </div>
          <div class="product-detail-content-right-item-right cursor" @click="operate('coupon')">
            <img src="../../../assets/images/icon-arrow-right.png" alt="">
          </div>
        </div>
        <div class="product-detail-content-right-item flex space-between">
          <div class="product-detail-content-right-item-left">
            <span class="title">服务</span>
            <span class="gray">7天无理由退货 /24小时内发货 假一赔十</span>
          </div>
          <div class="product-detail-content-right-item-right cursor" @click="operate('service')">
            <img src="../../../assets/images/icon-arrow-right.png" alt="">
          </div>
        </div>
        <div class="product-detail-content-right-item flex space-between">
          <div class="product-detail-content-right-item-left">
            <span class="title">参数</span>
            <span class="gray">品牌、规格…</span>
          </div>
          <div class="product-detail-content-right-item-right cursor" @click="operate('guige')">
            <img src="../../../assets/images/icon-arrow-right.png" alt="">
          </div>
        </div>
        <div class="messageSet-box"  v-if="messageSetList.length">
          <div class="product-detail-content-right-item flex space-between" v-for="(item, index) in messageSetList" :key="index">
            <div class="product-detail-content-right-item-left">
              <span class="title">{{ item.name }}</span>
              <span class="size cursor" 
                :class="[subItem.ishow ? 'active' : 'noactive', subIndex[index] == childIndex ? 'active' : '']"
                v-for="(subItem,childIndex) in item.item" 
                :key="childIndex"
                @click="skuClick(subItem,index,childIndex)">
                <!-- :class="[item.ishow ? '' : 'noactive', subIndex[index] == childIndex ? 'active' : '']" -->
                <!-- @click="operate('selectSize',index,item,childIndex)" -->
                <!-- :class="{'active': currentSizeIndex === subIndex}" -->
                <!-- {{ subItem.name }} -->
                {{ $base64.decode(subItem.name) }}
              </span>
            </div>
          </div>
        <!-- <div class="product-detail-content-right-item flex space-between">
          <div class="product-detail-content-right-item-left">
            <span class="title">颜色</span>
            <span class="size cursor" 
              :class="{'active': currentColorIndex === index}"
              v-for="(item,index) in colorArr" 
              :key="index"
              @click="operate('selectColor',index,item)">
              {{ item.name }}
            </span>
          </div>
        </div> -->
        </div>
        <!-- <div class="product-detail-content-right-item flex space-between" v-if="sizeArr.length">
          <div class="product-detail-content-right-item-left">
            <span class="title">尺码</span>
            <span class="size cursor" 
              :class="{'active': currentSizeIndex === index}"
              v-for="(item,index) in sizeArr" 
              :key="index"
              @click="operate('selectSize',index)">
              {{ item.name }}
            </span>
          </div>
        </div>
        <div class="product-detail-content-right-item flex space-between">
          <div class="product-detail-content-right-item-left">
            <span class="title">颜色</span>
            <span class="size cursor" 
              :class="{'active': currentColorIndex === index}"
              v-for="(item,index) in colorArr" 
              :key="index"
              @click="operate('selectColor',index,item)">
              {{ item.name }}
            </span>
          </div>
        </div> -->
        <div class="product-detail-content-right-item flex flex-start">
          <span class="title">库存：{{ currentSkuSelect.stock }}</span>
          
        </div>
        <div class="product-detail-content-right-item flex flex-start">
          <span class="title">订购数量：</span>
          <div class="cart-box flex flex-start">
            <div class="cart-box-sub cursor" @click="operate('sub')">
              <img src="../../../assets/images/card-sub.png" alt="">
            </div>
            <div class="cart-box-num">
              <el-input v-model.number="num" :min="1" :max="99" @blur="buyNumHandle (num)"></el-input>
            </div>
            <div class="cart-box-add cursor" @click="operate('add')">
              <img src="../../../assets/images/card-add.png" alt="">
            </div>
          </div>
        </div>
        <div class="product-detail-content-right-btn flex flex-start">
          <div class="product-detail-content-right-btn buy cursor" 
            @click="operate('buy')">
            立即购买
          </div>
          <div class="product-detail-content-right-btn cursor el-icon-shopping-cart-2 cart" v-if="productType" 
            @click="operate('cart')">
            加入购物车
          </div>
          <div class="product-detail-content-right-btn cursor like" v-if="productType && !productDetail.follow" @click="operate('collect')">
            <img src="../../../assets/images/like.png" alt="">
            收藏
          </div>
          <div class="product-detail-content-right-btn cursor like" style="color: #ff2b00;" v-if="productType && productDetail.follow" @click="operate('collect')">
            <img src="../../../assets/images/like-red.png" alt="">
            已收藏
          </div>
          <div class="product-detail-content-right-btn cursor el-icon-shopping-cart-2 cart" v-if="productType"
            @click="operate('goToCart')">
            购物车
          </div>
        </div>
      </div>
    </div>

    <!-- 详情tab切换 start -->
    <div class="product-detail-detail width1200">
      <div class="product-detail-detail-tab">
        <div class="product-detail-detail-tab-item cursor" 
          v-for="(item,index) in tabArr" 
          :key="index"
          :class="{'active': currentIndex === index}"
          @click="tabHandle(index)">
          {{ item.name }}
        </div>
      </div>
      <div class="product-detail-detail-tab-content">
        <div class="product-detail-detail-tab-content-detail" v-if="currentIndex === 0" v-html="productDetail.content"></div>
        <div class="product-detail-detail-tab-content-detail-evaluate" v-if="currentIndex === 1">
          <div class="evaluate">
            <div class="evaluate-box-tab">
              <div class="evaluate-box-tab-item" 
                v-for="(item,index) in evaluateArr" 
                :key="index"
                @click="tabRadioHandle(index,item)">
                <span class="evaluate-box-tab-radio cursor flex"
                  :class="{ 'active': tabRadioCurrentIndex == index }">
                  <span v-show="tabRadioCurrentIndex == index"></span>
                </span>
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="evaluate-box">
            <div class="evaluate-box-item" 
              v-for="(item,index) in evaluateContentArr" 
              :key="index">
              <div class="evaluate-box-item-left">
                <img :src="item.user.avatarShow" alt="">
              </div>
              <div class="evaluate-box-item-right">
                <div class="evaluate-box-item-right-name">
                  {{ item.user.nickname }}
                </div>
                <div class="evaluate-box-item-right-size flex space-between">
                  <span>规格：{{ item.suk }}</span>
                  <el-rate
                    v-model="item.score_deliver"
                    disabled
                    text-color="#ff9900"
                    score-template="{value}">
                  </el-rate>
                </div>
                <div class="evaluate-box-item-right-content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
          <div class="evaluate-box-page">
            <div class="evaluate-box-page-prev cursor" @click="operate('prev')" v-if="page > 1">
              上一页
            </div>
            <div class="evaluate-box-page-next cursor" @click="operate('next')" v-if="Math.ceil(total / 15) > 1 && page < Math.ceil(total / 15)">
              下一页
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情tab切换 end -->

    <!-- 店铺名字 start -->
    <div class="product-detail-shop width1200 flex space-between" v-if="productType">
      <div class="product-detail-shop-left flex flex-start">
        <div class="product-detail-shop-left-img">
          <img :src="productDetail && productDetail.shop && productDetail.shop.avatarShow" alt="">
        </div>
        <div class="product-detail-shop-left-content">
          <div class="product-detail-shop-left-content-title">
            {{ productDetail && productDetail.shop && productDetail.shop.shopname }}
          </div>
          <div class="product-detail-shop-left-content-desc">
            <span class="label">
              宝贝描述
              <span class="score">{{ productDetail && productDetail.shop && productDetail.shop.score_describe }}</span>
            </span>
            <span class="label">
              卖家服务
              <span class="score">{{ productDetail && productDetail.shop && productDetail.shop.score_service }}</span>
            </span>
            <span class="label">
              物流服务
              <span class="score">{{ productDetail && productDetail.shop && productDetail.shop.score_logistics }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="product-detail-shop-right flex space-between">
        <div class="product-detail-shop-right-all cursor" @click="operate('allProduct')">
          全部宝贝
        </div>
        <div class="product-detail-shop-right-in cursor" @click="operate('comeInShop')">
          进店逛逛
        </div>
      </div>
    </div>
    <!-- 店铺名字 end -->

    <!-- 店铺推荐 start -->
    <div class="width1200 product-detail-shop-hot" v-if="productType">
      <div class="product-detail-shop-hot-up flex space-between">
        <div class="product-detail-shop-hot-up-title">
          店铺推荐
        </div>
        <div class="product-detail-shop-hot-up-see flex cursor"  @click="operate('comeInShop')">
          查看全部
          <img src="../../../assets/images/arrow-right.png" alt="">
        </div>
      </div>
      <div class="product-detail-shop-hot-down">
        <div class="product-detail-shop-hot-down-item cursor" 
          v-for="(item,index) in hotProductArr" 
          :key="index"
          @click="productDetailHandle(item)">
          <div class="product-detail-shop-hot-down-item-img">
            <img :src="item.img" alt="">
          </div>
          <div class="product-detail-shop-hot-down-item-content flex space-between">
            <div class="product-detail-shop-hot-down-item-content-left">
              {{ item.title }}
            </div>
            <div class="product-detail-shop-hot-down-item-content-right">
              ¥{{ item.price }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 店铺推荐 end -->

    <!-- 商品推荐 start -->
    <div class="width1200 product-detail-hot-product" v-if="productType">
      <div class="product-detail-hot-product-title">
        <span>商品推荐</span>
      </div>
      <div class="product-detail-hot-product-content">
        <productCard ref="productCard" :sellArr="sellArr"></productCard>
      </div>
    </div>
    <!-- 商品推荐 end -->

    <!-- 弹框 start -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="428px"
      class="custom-header"
      :before-close="handleClose">
      <div class="custom-header-box">
        <!-- 优惠券 start -->
        <div class="custom-header-box-main" v-if="dialogVisibleCoupon">
          <div class="custom-header-box-item" 
            v-for="(item,index) in couponArr" 
            :key="index">
            <div class="custom-header-box-item-left">
              <div class="custom-header-box-item-left-price">
                ¥
                <strong>{{ item.discount }}</strong>
              </div>
              <div class="full-use">
                满{{ item.validity }}减 {{ item.userlevel }}
              </div>
            </div>
            <div class="custom-header-box-item-right">
              <div class="custom-header-box-item-right-title">
                {{ item.pretype_text }}
              </div>
              <div class="custom-header-box-item-right-num" v-if="item.pretype != 'fixed'">
                {{ item.content }}
              </div>
              <div class="custom-header-box-item-right-num" v-if="item.pretype === 'fixed'">
                {{ item.startdate }} - {{ item.enddate }}
              </div>
              <div class="custom-header-box-item-right-btn cursor" @click="operate('receive')">
                立即领取
              </div>
            </div>
          </div>
        </div>
        <!-- 优惠券 end -->

        <!-- 服务 start -->
        <div class="custom-header-box-main" v-if="dialogVisibleService">
          <div class="custom-header-box-main-item">
            <div class="title">
              7天无理由退款
            </div>
            <div class="content">
              满足退款条件，用户可申请七天无理由退款
            </div>
          </div>
          <div class="custom-header-box-main-item">
            <div class="title">
              24小时内发货
            </div>
            <div class="content">
              商家保障，24小时内发出货品
            </div>
          </div>
          <div class="custom-header-box-main-item">
            <div class="title">
              48小时内发货
            </div>
            <div class="content">
              商家保障，48小时内发出货品
            </div>
          </div>
          <div class="custom-header-box-main-item">
            <div class="title">
              急速退款
            </div>
            <div class="content">
              信誉用户在退货后，急速退款至账户
            </div>
          </div>
          <div class="custom-header-box-main-item">
            <div class="title">
              假一赔十
            </div>
            <div class="content">
              正品保障，假一赔100
            </div>
          </div>
        </div>
        <!-- 服务 end -->

        <!-- 规格 start -->
        <div class="custom-header-box-main" v-if="dialogVisibleGuiGe">
          <div class="custom-header-box-main-item guige flex flex-start">
            <div class="label">
              品牌
            </div>
            <div class="val">
              {{ productDetail.brand.name }}
            </div>
          </div>
        </div>
        <!-- 规格 end -->
      </div>
    </el-dialog>
    <!-- 弹框 end -->
  </div>
</template>

<script>
import search from '@/components/shoppingMall/search.vue'
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import productCard from '@/components/shoppingMall/productCard.vue'
import { wanlshopProductGoods, wanlshopList, wanlshopProductComment, wanlshopProductFollow,
  wanlshopCartStorage } from '../../../api/shoppingMall'
import { JsxFlags } from 'typescript';
export default {
  components: {
    search,
    crumbsVue,
    productCard,
  },
  props:{
    
  },
  data() {
    return {
      navs: [
        {
          title: '首页',
          path: 'home'
        }, {
          title: '商品详情页',
        },
      ],
      lunboArr: [],
      sizeArr: [],
      colorArr: [],
      num: 1,
      tabArr: [{
        name: '产品详情',
        type: 1
      }],
      currentIndex: 0,
      hotProductArr: [],
      sellArr: [],
      radio: null,
      evaluateArr: [{
        label: '全部评价',
        field: '',
        checked: true,
        value: 0
      },{
        label: '好评',
        field: 'good',
        checked: false,
        value: 1
      },{
        label: '中评',
        field: 'pertinent',
        checked: false,
        value: 0
      },{
        label: '差评',
        field: 'poor',
        checked: false,
        value: 0
      }],
      evaluateContentArr: [],
      dialogVisible: false,
      dialogVisibleCoupon: false,
      dialogVisibleService: true,
      dialogVisibleGuiGe: false,
      couponArr: [],
      currentSizeIndex: 0,
      currentColorIndex: 0,
      productType: '', // 根据类型判断是商品点进来的还是积分进来的
      currentImg: '',
      currentImgArr: [],
      currentCardItem: {},
      title: '',
      productDetail: {},
      tabRadioCurrentIndex: 0,
      page: 1, // 默认第一页
      total: 0, // 评价总条数
      lunboIndex: 0,
      currentSkuSelect: {}, // 当前选中的SKU
      messageSetList: [], // 关于尺码颜色信息配置
      selectArr: [], //存放被选中的值
			subIndex: [], //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
			selectshop: {}, //存放最后选中的商品
      shopItemInfo: {}, //存放要和选中的值进行匹配的数据
    }
  },
  created () {
    this.productType = this.$route.query.type ? this.$route.query.type : ''
    this.currentCardItem = this.$route.query.item ? JSON.parse(this.$route.query.item) : {}
    if (this.productType) {
      this.tabArr.push({
        name: '宝贝评价',
        type: 2
      })
    }
    if (this.currentCardItem.id) {
      this.getWanlshopProductGoods(this.currentCardItem.id)
    }
    this.getWanlshopList()
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 商品规格组合 选择
     * @param {*} value
     * @param {*} spukey
     * @param {*} event
     * @param {*} index
     */    
		skuClick(value, spukey, index) {
      if (this.messageSetList[spukey].item.length) {
        this.messageSetList[spukey].item.map((child, childIndex) => {
          if (index == childIndex) {
            child.ishow = true
          } else {
            child.ishow = false
          }
        })
      }
			if (value.ishow) {
				if (this.selectArr[spukey] != value.name) {
					this.$set(this.selectArr, spukey, value.name);
					this.$set(this.subIndex, spukey, index);
				} else {
					this.$set(this.selectArr, spukey, '');
					this.$set(this.subIndex, spukey, -1);
				}
				// this.checkInpath(spukey);
				//如果全部选完
				if (this.selectArr.every(item => item != '')) {
					this.selectshop = this.shopItemInfo[this.selectArr];
					this.selectshop && this.selectshop.difference.map((name, index, arr)=> {
						arr[index] = this.$base64.decode(name)
					})
					this.selectNum = 1;
				}
        console.log("selectshop=",this.selectshop);
        this.currentSkuSelect = this.selectshop
			}
		},
    /**
     * @Author: angel~dongsanling
     * @description: 手动输入购买数量
     * @param {*} val
     */    
    buyNumHandle (val) {
      if (val > this.currentSkuSelect.stock) {
        this.num = this.currentSkuSelect.stock
      } else {
        this.num = val
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 推荐店铺商品详情
     * @param {*} data 当前商品数据
     */    
    productDetailHandle (data) {
      this.$router.push({
        path: '/shoppingMall/product-detail',
        query: {
          type: 'product',
          item: JSON.stringify(data)
        }
      })
      this.currentCardItem = data
      if (this.currentCardItem.id) {
        this.getWanlshopProductGoods(this.currentCardItem.id)
      }
      this.getWanlshopList()
    },
    /**
     * @Author: angel~dongsanling
     * @description: 搜索回调
     * @param {*} data 搜索框内容
     */    
    search (data) {
      this.$router.push({
        path: 'home',
        query: {
          'search': data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 评价切换
     * @param {*} index 当前的index
     * @param {*} data 当前数据
     */    
    tabRadioHandle (index, data) {
      this.tabRadioCurrentIndex = index
      this.tag = data.field
      this.getWanlshopProductComment(this.currentCardItem.id)
    },
    /**
     * @Author: angel~dongsanling
     * @description: 评论
     */    
    getWanlshopProductComment (id) {
      this.evaluateContentArr = []
      let params = {
        id: id ? id : '',
        page: this.page,
        tag: this.tag ? this.tag : ''
      }
      wanlshopProductComment(params).then(res => {
        if (res.code == 1) {
          if (res.data.comment.data) {
            res.data.comment.data.map((item, index) => {
              item.user.avatarShow = `${this.$store.getters.imgeCDN}${item.user.avatar}`
            })
          }
          this.evaluateArr[0].value = res.data.statistics.total
          this.radio = this.evaluateArr[0].checked
          this.evaluateArr[0].label =`全部评价（${res.data.statistics.total}）`
          this.evaluateArr[1].value = res.data.statistics.good
          this.evaluateArr[1].label =`好评（${res.data.statistics.good}）`
          this.evaluateArr[2].value = res.data.statistics.pertinent
          this.evaluateArr[2].label =`中评（${res.data.statistics.pertinent}）`
          this.evaluateArr[3].value = res.data.statistics.poor
          this.evaluateArr[3].label =`差评（${res.data.statistics.poor}）`
          this.evaluateContentArr = res.data.comment.data
          this.total = res.data.comment.totla
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取商品列表
     */    
    getWanlshopList () {
      this.sellArr = []
      wanlshopList({ page: 1 }).then(res => {
        if (res.code == 1) {
          if (res.data.data.length) {
            res.data.data.map((item, index) => {
              item.img = `${this.$store.getters.imgeCDN}${item.image}`
            })
          }
          this.sellArr = res.data.data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 商品详情
     * @param {*} id 当前商品id
     */    
    getWanlshopProductGoods (id) {
      this.colorArr = []
      this.lunboArr = []
      this.couponArr = []
      this.currentImgArr = []
      wanlshopProductGoods({ id: id }).then(res => {
        if (res.code == 1) {
          if (res.data) {
            res.data.img = `${this.$store.getters.imgeCDN}${res.data.image}`
            res.data.shop.avatarShow = `${this.$store.getters.imgeCDN}${res.data.shop.avatar}`
            res.data.shop.sendAddress = res.data.shop.city.split('/')[1]
            if (res.data.shop_recommend.length) {
              res.data.shop_recommend.map((item, index) => {
                item.img = `${this.$store.getters.imgeCDN}${item.image}`
              })
            }
            if (res.data.spu.length) {
              res.data.spu.map((item, index) => {
                if (item.name.includes('颜色')) {
                  this.colorArr = item.item
                }
                if (item.name.includes('尺寸')) {
                  this.sizeArr = item.item
                }
              })
            }
          }
          if (res.data.images.length) {
            res.data.images.map((item, index) => {
              this.lunboArr.push({
                img: `${this.$store.getters.imgeCDN}${item}`
              })
            })
          }
          if (res.data.coupon.length) {
            res.data.coupon.map((item, index) => {
              if (item.type === 'reduction') { // 过滤折扣券
                this.couponArr.push(item)
              }
            })
          }
          this.messageSetList = res.data.spu
          this.productDetail = res.data
          this.hotProductArr = res.data.shop_recommend
          this.currentImg = res.data.img
          this.currentImgArr.push(res.data.img)
          // 商品规格组合
					this.productDetail.spu.map(item => {
						item.item.map((name, key, arr) =>{
							arr[key].name = this.$base64.encode(arr[key].name)
						});
            item.ishow = false
						this.selectArr.push('');
						this.subIndex.push(-1);
					});
					this.productDetail.sku.map(item => {
						item.difference.map((name, index, arr) =>{
							arr[index] = this.$base64.encode(name)
						});
					});
					this.checkItem(); //计算sku里面规格形成路径
					this.checkInpath(-1); //传-1是为了不跳过循环
          this.currentSkuSelect = this.productDetail.sku && this.productDetail.sku[0]
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 关闭弹框
     */    
    handleClose () {
      this.dialogVisible = false
      this.dialogVisibleService = false
      this.dialogVisibleCoupon = false
      this.dialogVisibleGuiGe = false
    },
    /**
     * @Author: angel~dongsanling
     * @description: 循环所有属性判断哪些属性可选
     * @param {*} clickIndex
     */    
		checkInpath(clickIndex) {
			//当前选中的兄弟节点和已选中属性不需要循环
			for (let i = 0, len = this.messageSetList.length; i < len; i++) {
				if (i == clickIndex) {
					continue;
				}
        let len2 = this.messageSetList[i].item.length;
        for (let j = 0; j < len2; j++) {
          if (this.subIndex[i] != -1 && j == this.subIndex[i]) {
            continue;
          }
          let choosed_copy = [...this.selectArr];
          this.$set(choosed_copy, i, this.messageSetList[i].item[j].name);
          let choosed_copy2 = choosed_copy.filter(item => item !== '' && typeof item !== 'undefined');
          if (this.shopItemInfo.hasOwnProperty(choosed_copy2)) {
            this.$set(this.messageSetList[i].item[j], 'ishow', false);
            this.$forceUpdate()
          } else {
            this.$set(this.messageSetList[i].item[j], 'ishow', false);
          }
        
        }
			}
		},
    /**
     * @Author: angel~dongsanling
     * @description: 计算有多小种可选路径
     */    
		checkItem() {
			let result = this.productDetail.sku.reduce(
				(arrs, items) => {
					return arrs.concat(
						items.difference.reduce(
							(arr, item) => {
								return arr.concat(
									arr.map(item2 => {
										//利用对象属性的唯一性实现二维数组去重
										if (!this.shopItemInfo.hasOwnProperty([...item2, item])) {
											this.shopItemInfo[[...item2, item]] = items;
										}
										return [...item2, item];
									})
								);
							},
							[[]]
						)
					);
				},
				[[]]
			);
		},
    /**
     * @Author: angel~dongsanling
     * @description: 操作
     * @param {*} type 根据类型判断是哪个事件
     * @param {*} index 当前index
     * @param {*} data 当前数据
     */        
    operate (type,index,data,subIndex) {
      this.currentImgArr = []
      switch (type) {
        case 'sub':
          if (this.num > 1){
            this.num --
            // this.currentSkuSelect.stock ++
          } else {
            this.num = 1
          }
          break
        case 'add':
          if (this.currentSkuSelect.stock > this.num) {
            this.num ++
            // this.currentSkuSelect.stock -- 
          }
          break
        case 'prev':
          if (this.page > 1) {
            this.page = this.page - 1
          }
          break
        case 'next':
          if (Math.ceil(this.total / 15) > this.page) {
            this.page = this.page + 1
          } else {
            this.page = Math.ceil(this.total / 15)
          }
          break
        case 'receive': // 立即领取
          break
        case 'selectColor':
          this.currentColorIndex = index
          if (this.productDetail.sku.length) {
            this.productDetail.sku.map((item, index) =>{
              if (item.difference.includes(this.colorArr[this.currentColorIndex].name)){
                this.currentSkuSelect = item
              }
            })
          }
          break
        case 'selectSize':
          this.currentSizeIndex = index
          if (this.productDetail.sku.length) {
            this.productDetail.sku.map((item, index) =>{
              if (item.difference.includes(this.sizeArr[this.currentSizeIndex].name)){
                this.currentSkuSelect = item
              }
            })
          }
          break
        case 'coupon':
          this.handleClose()
          this.dialogVisible = true
          this.dialogVisibleCoupon = true
          this.title = '优惠券'
          break
        case 'allProduct':
          this.$router.push({
            path: 'all-products',
            query: {
              shop_id: this.productDetail.shop_id
            }
          })
        case 'comeInShop':
          this.$router.push({
            path: 'come-in-shop',
            query: {
              shop_id: this.productDetail.shop_id
            }
          })
          break
        case 'lunbo':
          this.currentImg = data.img
          this.lunboIndex = index
          this.currentImgArr.push(this.currentImg)
          break
        case 'lunboLeft': // 左侧点击
          if (index > 0) {
            this.lunboIndex = index - 1
            this.currentImg = this.lunboArr[this.lunboIndex].img
            this.currentImgArr.push(this.currentImg)
          } else {
            this.lunboIndex = this.lunboArr.length - 1
            this.currentImg = this.lunboArr[this.lunboIndex].img
            this.currentImgArr.push(this.currentImg)
          }
          break
        case 'lunboRight': // 右侧点击
          if (this.lunboArr.length > index + 1){
            this.lunboIndex = index + 1
            this.currentImg = this.lunboArr[this.lunboIndex].img
            this.currentImgArr.push(this.currentImg)
          } else {
            this.lunboIndex = 0
            this.currentImg = this.lunboArr[this.lunboIndex].img
            this.currentImgArr.push(this.currentImg)
          }
          break
        case 'service': // 服务
          this.handleClose()
          this.title = '基础服务保障'
          this.dialogVisible = true
          this.dialogVisibleService = true
          break
        case 'guige': // 规格
          this.handleClose()
          this.title = '产品参数'
          this.dialogVisible = true
          this.dialogVisibleGuiGe = true
          break
        case 'buy': // 立即购买
          if (this.messageSetList.length) {
            for(let i = 0 ; i < this.messageSetList.length ; i ++) {
              for (let j = 0 ; j < this.messageSetList[i].item.length ; j ++) {
                if (this.messageSetList[i].item[j].ishow) {
                  this.messageSetList[i].ishow = true
                }
              }
            }
            for(let i = 0 ; i < this.messageSetList.length ; i ++) {
              if (!this.messageSetList[i].ishow) {
                this.$message.error("请选择规格")
                return 
              }
            }
          }
          // 首先判断是否有token，如果没有token则跳转到登录页面
          let buyArr = [{
            number: this.num,
            goods_id: this.productDetail.id,
            sku_id: this.selectshop.id
          }]
          this.$router.push({
            path: 'confirm-order',
            query: {
              navType: '订单结算',
              data: JSON.stringify(buyArr)
            }
          })
          break
        case 'cart': // 加入购物车
          if (this.messageSetList.length) {
            for(let i = 0 ; i < this.messageSetList.length ; i ++) {
              for (let j = 0 ; j < this.messageSetList[i].item.length ; j ++) {
                if (this.messageSetList[i].item[j].ishow) {
                  this.messageSetList[i].ishow = true
                }
              }
            }
            for(let i = 0 ; i < this.messageSetList.length ; i ++) {
              if (!this.messageSetList[i].ishow) {
                this.$message.error("请选择规格")
                return 
              }
            }
          }
          let params = {
            type: "add",
            data: {
              goods_id: this.productDetail.id,
              image: this.productDetail.image,
              number: this.num,
              shop_id: this.productDetail.shop_id,
              shop_name: this.productDetail.shop.shopname,
              sku: this.selectshop,
              sku_id: this.selectshop.id,
              sum: this.productDetail.price,
              title: this.productDetail.title,
            }
          }
          wanlshopCartStorage(params).then(res => {
            if (res.code == 1) {
              this.$message.success(res.msg)
            } else {
              this.$message.error(res.msg)
            }
          })
          break
        case 'collect': // 收藏
          wanlshopProductFollow({ id: this.currentCardItem.id }).then(res => {
            if (res.code == 1) {
              this.$message.success('操作成功')
              this.productDetail.follow = !this.productDetail.follow
            }
          })
          break
        case 'goToCart':
          this.$router.push({
            path: 'shop-cart'
          })
          break
      }
    },
    
    /**
     * @Author: angel~dongsanling
     * @description: tab切换
     * @param {*} index 当前的index
     */    
    tabHandle (index) {
      this.currentIndex = index
      if (index == 1){
        this.getWanlshopProductComment(this.currentCardItem.id)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.product-detail{
  background: #fff !important;
  .product-detail-search{
    .product-detail-search-box{
    }
  }
  .product-detail-crumbs{
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 35px;
    border-top: 1px solid #DFDFDF;
  }
  .product-detail-content{
    // height: 590px;
    padding: 30px;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    margin-bottom: 60px;
    .product-detail-content-left{
      width: 510px;
      .product-detail-content-left-img{
        width: 510px;
        height: 450px;
        .el-image{
          width: 100%;
          height: 100%;
        }
      }
      .product-detail-content-left-bottom{
        margin-top: 30px;
        .product-detail-content-left-bottom-left,.product-detail-content-left-bottom-right{
          width: 13px;
          height: 13px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .product-detail-content-left-bottom-center{
          flex: 1;
          height: 50px;
          margin-left: 27px;
          .product-detail-content-left-bottom-center-item{
            width: 50px;
            height: 50px;
            margin-right: 15px;
            &.active{
              border: 1px solid #2676F7;
            }
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .product-detail-content-right{
      height: 100%;
      flex: 1;
      margin-left: 30px;
      .product-detail-content-right-title{
        font-size: 24px;
        color: #222222;
        line-height: 32px;
        margin-bottom: 20px;
      }
      .product-detail-content-right-price{
        height: 102px;
        padding-left: 20px;
        background: #F9F9F9;
        padding: 15px 20px 0px;
        margin-bottom: 20px;
        .product-detail-content-right-price-up{
          .new-price{
            font-size: 30px;
            color: #FF1515;
            line-height: 40px;
            .integral{
              font-size: 20px;
            }
          }
          .old-price{
            font-size: 16px;
            color: #999999;
            line-height: 21px;
            text-align: left;
            margin-left: 20px;
          }
        }
        .product-detail-content-right-price-down{
          span{
            margin-right: 62px;
          }
        }
      }
      .product-detail-content-right-item{
        margin-bottom: 24px;
        .product-detail-content-right-item-left{
          display: flex;
          align-items: center;
        }
        .title{
          font-size: 18px;
          color: #333333;
          line-height: 25px;
          text-align: left;
          font-style: normal;
          margin-right: 20px;
        }
        .gray{
          font-size: 16px;
          color: #999999;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
        .quan{
          width: 28px;
          height: 23px;
          line-height: 23px;
          padding: 0px 4px;
          display: inline-block;
          background: #FF4444;
          border-radius: 5px 0px 0px 5px;
          border: 1px solid #FF4444;
          font-size: 12px;
          color: #fff;
        }
        .full-quan{
          // width: 62px;
          height: 23px;
          line-height: 23px;
          padding: 0 2px 0px 5px;
          display: inline-block;
          color: #FF4444;
          font-size: 12px;
          background: #FDEBE8;
          border: 1px solid #FF4444;
          border-left: none;
        }
        .size{
          display: inline-block;
          // width: 70px;
          height: 35px;
          padding: 0px 10px;
          font-size: 16px;
          color: #666;
          text-align: center;
          line-height: 35px;
          background: #FAFAFA;
          border-radius: 18px;
          border: 1px solid #F4F4F4;
          margin-right: 20px;
          &.active{
            color: #2676F7;
            background: rgba(38, 118, 247, 0.13);
          }
        }
        .cart-box{
          .cart-box-num{
            width: 40px;
            height: 32px;
            background: #F8F8F8;
            border: none;
            .el-input{
              width: 40px;
              height: 32px;
              background: #F8F8F8;
              border: none;
              /deep/.el-input__inner{
                height: 32px !important;
                padding: 0px !important;
                background: #F8F8F8;
                border: none !important;
                text-align: center;
              }
            }
          }
        }
      }
      .product-detail-content-right-btn{
        margin-right: 20px;
        line-height: 40px;
        text-align: center;
        .product-detail-content-right-btn{
          &.buy{
            width: 92px;
            height: 40px;
            font-size: 14px;
            color: #2676F7;
            text-align: center;
            background: #D6E6FF;
            border: 1px solid #2676F7;
          }
          &.cart{
            width: 127px;
            height: 40px;
            font-size: 14px;
            color: #fff;
            background: #2676F7;
          }
          &.like{
            width: 74px;
            height: 40px;
            background: #FAFAFA;
            border: 1px solid #E8E8E8;
            img{
              width: 17px;
              height: 17px;
              display: inline-block;
              position: relative;
              top: 4px;
            }
          }
        }
      }
    }
  }
  .product-detail-detail{
    .product-detail-detail-tab{
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #F8F8F8;
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #E8E8E8;
      .product-detail-detail-tab-item{
        width: 180px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.active{
          background: #FFFFFF;
          border-radius: 4px 0px 0px 0px;
          border: 1px solid #E8E8E8;
          border-top: 2px solid #2676F7;
        }
      }
      
    }
    .product-detail-detail-tab-content{
      
      border-radius: 4px;
      border: 1px solid #F1F1F1;
      .product-detail-detail-tab-content-detail{
        padding: 30px;
        p{
          font-size: 16px;
          line-height: 28px;
        }
      }
      .product-detail-detail-tab-content-detail-evaluate{
        .evaluate{
          height: 50px;
          padding-left: 30px;
          background: #FFFFFF;
          border: 1px solid #E8E8E8;
          display: flex;
          align-items: center;
          border-top: none;
          .evaluate-box-tab{
            display: flex;
            align-items: center;
            .evaluate-box-tab-item{
              display: flex;
              align-items: center;
              margin-right: 15px;
              .evaluate-box-tab-radio{
                width: 14px;
                height: 14px;
                background: #FFFFFF;
                border: 1px solid #E6E6E6;
                border-radius: 50%;
                position: relative;
                margin-right: 15px;
                &.active{
                  border: 1px solid #2676F7 !important;
                }
                span{
                  width: 6px;
                  height: 6px;
                  display: inline-block;
                  background: #2676F7;
                  border-radius: 50%;
                }
              }
            }
          }
        }
        .evaluate-box{
          padding: 30px;
          .evaluate-box-item{
            display: flex;
            margin-bottom: 40px;
            .evaluate-box-item-left{
              width: 50px;
              height: 50px;
              margin-right: 20px;
              img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .evaluate-box-item-right{
              flex: 1;
              .evaluate-box-item-right-name{
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
              }
              .evaluate-box-item-right-size{
                font-weight: 400;
                font-size: 14px;
                color: #999999;
                margin-bottom: 15px;
              }
              .evaluate-box-item-right-content{
                font-weight: 400;
                font-size: 14px;
                color: #666666;
              }
            }
          }
        }
        .evaluate-box-page{
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 40px;
          .evaluate-box-page-prev,.evaluate-box-page-next{
            width: 74px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            margin-right: 40px;
          }
        }
      }
    }
  }
  .product-detail-shop{
    height: 140px;
    padding: 30px;
    margin-top: 60px;
    margin-bottom: 30px;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    .product-detail-shop-left{
      .product-detail-shop-left-img{
        width: 80px;
        height: 80px;
        margin-right: 20px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .product-detail-shop-left-content{
       .product-detail-shop-left-content-title{
        font-size: 24px;
        color: #333;
        font-weight: 500;
        margin-bottom: 26px;
       } 
       .product-detail-shop-left-content-desc{
        .label{
          font-size: 14px;
          color: #999;
          margin-right: 40px;
        }
        .score{
          margin-left: 7px;
          font-weight: bold;
          font-size: 16px;
          color: #FF8F00;
        }
       }
      }
    }
    .product-detail-shop-right{
      .product-detail-shop-right-all{
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #2676F7;
        background: #FFFFFF;
        border-radius: 20px;
        border: 1px solid #2676F7;
        margin-right: 20px;
      }
      .product-detail-shop-right-in{
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        background: #2676F7;
        border-radius: 20px;
        border: 1px solid #2676F7;
      }
    }
  }
  .product-detail-shop-hot{
    padding: 30px;
    border-radius: 4px;
    border: 1px solid #F1F1F1;
    padding-bottom: 0px;
    margin-bottom: 60px;
    .product-detail-shop-hot-up{
      padding-right: 100px;
      margin-bottom: 20px;
      .product-detail-shop-hot-up-title{
        font-weight: 500;
        font-size: 18px;
        color: #333333;
      }
      .product-detail-shop-hot-up-see{
        font-size: 16px;
        color: #999;
      }
    }
    .product-detail-shop-hot-down{
      display: flex;
      align-items: center;
      // justify-content: space-between;
      flex-wrap: wrap;
      .product-detail-shop-hot-down-item{
        width: 275px;
        margin-bottom: 30px;
        margin-right: 13px;
        .product-detail-shop-hot-down-item-img{
          width: 275px;
          height: 300px;
          margin-bottom: 20px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .product-detail-shop-hot-down-item-content{
          .product-detail-shop-hot-down-item-content-left{
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .product-detail-shop-hot-down-item-content-right{
            font-weight: 500;
            font-size: 16px;
            color: #FF2213;
          }
        }
      }
      .product-detail-shop-hot-down-item:nth-child(4n){
        margin-right: 0px !important;
      }
    }
  }
  .product-detail-hot-product{
    padding-bottom: 30px;
    .product-detail-hot-product-title{
      margin-bottom: 30px;
      span{
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        padding-bottom: 3px;
        border-bottom: 4px solid #2676F7;
        
      }
    }
  }
  .custom-header{
    /deep/.el-dialog__body{
      padding: 0px !important;
      padding-bottom: 20px !important;
      border-top: 1px solid #f4f4f4;
      margin: 0px 15px;
    }
    .custom-header-box{
      box-sizing: border-box;
      .custom-header-box-item{
        width: 100%;
        height: 105px;
        padding: 13px 0px;
        display: flex;
        justify-content: flex-start;
        background: #FFF5F2;
        position: relative;
        border-radius: 5px;
        margin-top: 20px;
        .custom-header-box-item-left{
          width: 100px;
          position: relative;
          .custom-header-box-item-left-price{
            color: #FF2424;
            text-align: center;
            strong{
              font-size: 30px;
              font-weight: 500;
              color: #FF2424;
            }
          }
          .full-use{
            font-size: 14px;
            color: #FF2424;
            text-align: center;
          }
        }
        .custom-header-box-item-left::after{
          content: '';
          width: 0px;
          height: 79px;
          border-radius: 2px;
          border-left: 1px dotted #FFE6DF;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
        }
        .custom-header-box-item-right{
          position: relative;
          padding-left: 18px;
          .custom-header-box-item-right-title{
            padding-top: 14px;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            margin-bottom: 6px;
          }
          .custom-header-box-item-right-num{
            font-size: 14px;
            color: #999;
          }
          .custom-header-box-item-right-btn{
            width: 90px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            color: #FF2424;
            border-radius: 16px;
            border: 1px solid #FF2424;
            position: absolute;
            right: -115px;
            bottom: 7px;
          }
        }
        .custom-header-box-item-right::before{
          content: '';
          width: 15px;
          height: 15px;
          background: #fff;
          border-radius: 50%;
          position: absolute;
          left: -8px;
          top: -20px;
        }
        .custom-header-box-item-right::after{
          content: '';
          width: 15px;
          height: 15px;
          background: #fff;
          border-radius: 50%;
          position: absolute;
          left: -8px;
          top: 85px;
        }
      }
      .custom-header-box-item::after{
        content: '满减券';
        width: 61px;
        height: 70px;
        color: #fff;
        position: absolute;
        right: 0px;
        top: 10px;
        transform: rotate(45deg);
      }
      .custom-header-box-item::before{
        content: '';
        width: 61px;
        height: 58px;
        color: #fff;
        background: url(../../../assets/images/coupon-type.png) no-repeat center center;
        position: absolute;
        right: 0px;
        top: 0px;
      }
      .custom-header-box-main{
        .custom-header-box-main-item{
          .title{
            font-size: 16px;
            color: #333;
            padding: 10px 0px;
          }
          .content{
            font-size: 14px;
            color: #999;
          }
          .label{
            font-size: 16px;
            color: #999;
          }
          .val{
            font-size: 16px;
            color: #333;
          }
          &.guige{
            padding: 20px 0px;
            .label{
              margin-right: 50px;
            }
          }
        }
      }
    }
  }
}
        
</style>