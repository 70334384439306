<template>
  <div class="home-index">
    <div class="home-index-category">
      <div class="home-index-category-box width1200">
        <div class="home-index-category-box-left">
          <div class="home-index-category-box-item cursor" 
            :class="{'active': index == currentIndex }" 
            v-for="(item,index) in categoryArr" 
            :key="index"
            @click="tabCategory(index,item)">
            {{ item.name }}
          </div>
        </div>
        <div class="home-index-category-box-right cursor">
          <el-popover
            ref="setpopover"
            placement="bottom-end"
            popper-class="custom-popover-myself-shopping"
            trigger="click"
            v-model="visible">
            <div class="cont">
              <div class="cont-item cursor" 
                :class="{'active': currentMoreIndex === index}"
                v-for="(item, index) in categoryArr" 
                :key="index" @click="selectCategoryHandle(index, item)">
                <span>{{ item.name }}</span>
              </div>
            </div>
            <img slot="reference" src="../../../assets/images/arrow-down-black.png" alt="">
          </el-popover>
        </div>
      </div>
    </div>
    <div class="home-index-banner">
      <el-carousel trigger="click">
        <el-carousel-item v-for="(item, index) in lunbotu" :key="index">
          <img :src="item.img" alt="">
        </el-carousel-item>
      </el-carousel>
      
    </div>
    <div class="home-index-main width1200">
      <!-- 搜索框 start -->
      <div class="home-index-main-search" @click.stop>
        <div class="home-index-main-search-box">
          <el-input class="search-input" v-model="search" @focus="focusHandle" placeholder="搜索商品"></el-input>
          <div class="search-btn cursor" @click="operate('search')">搜索</div>
        </div>
        <div class="home-index-main-search-content" :class="{'active': focusFlag}">
          <div class="home-index-main-search-content-history">
            <div class="home-index-main-search-content-history-title flex space-between">
              <div class="home-index-main-search-content-history-left">
                历史搜索
              </div>
              <div class="home-index-main-search-content-history-right cursor" @click.stop="deleteHistory()">
                <img src="../../../assets/images/delete-black.png" alt="">
              </div>
            </div>
            <div class="home-index-main-search-content-history-content">
              <div class="home-index-main-search-content-history-content-item cursor" 
                v-for="(item,index) in historyArr" 
                :key="index"
                @click="searchHandle(item)">
                {{ item.keywords }}
              </div>
            </div>
          </div>
          <div class="home-index-main-search-content-hot">
            <div class="home-index-main-search-content-hot-title flex flex-start">
              <img src="../../../assets/images/hot.png" alt="">
              <span>热门搜索</span>
            </div>
            <div class="home-index-main-search-content-hot-content">
              <div class="home-index-main-search-content-hot-content-item cursor" 
                v-for="(item,index) in searchArr" 
                :key="index"
                @click="searchHandle(item)">
                {{ item.keywords }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 搜索框 end -->
      
      <!-- 推荐卡片 start -->
      <div class="home-index-main-card" v-if="!searchFlag && currentIndex === 0">
        <div class="home-index-main-card-item" v-for="(item,index) in moduleArr" :key="index" @click="cardClick(item)">
          <img :src="item.img" alt="">
        </div>
      </div>
      <!-- 推荐卡片 end -->

      <!-- 搜索后排序 start -->
      <div class="home-index-main-sort flex space-between" v-if="searchFlag">
        <div class="home-index-main-sort-box flex space-between">
          <div class="home-index-main-sort-box-item cursor" 
            :class="{'active': currentSortIndex === index, 'sort-active': item.type == 5}" 
            v-for="(item,index) in sortArr" 
            :key="index"
            @click="sortHandle(index,item)">
            {{ item.name }}
          </div>
        </div>
        <!-- <div class="home-index-main-sort-img cursor sort-active" @click="sortHandle(4,{type: 5})">
          <img src="../../../assets/images/icon-all.png" class="sort-active" alt="">
        </div> -->
        <div class="screen" v-if="screenFlag">
          <div class="screen-city flex space-between">
            <div class="screen-city-label">
              发货地
            </div>
            <div class="screen-city-value cursor">
              <div class="sort-active" @click="selectCity">
                <span class="sort-active">{{ sendGoodAddress }}</span>
                <span class="el-icon-arrow-right sort-active"></span>
              </div>
              <div class="screen-city-box" v-if="cityFlag">
                <div class="screen-city-box-item sort-active" 
                  :class="{'active': currentIndexCity === index}" 
                  v-for="(item, index) in city" 
                  :key="index"
                  @click="handleCity(index, item)">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="screen-price">
            <div class="screen-price-label">
              价格区间
            </div>
            <div class="screen-price-value flex space-between">
              <el-input v-model="minVal" placeholder="最低价" oninput="value = value.replace(/^\D*([0-9]\d*\.?\d{0,4})?.*$/,'$1')"></el-input>
              -　
              <el-input v-model="maxVal" placeholder="最高价" oninput="value = value.replace(/^\D*([0-9]\d*\.?\d{0,4})?.*$/,'$1')"></el-input>
            </div>
          </div>
          <div class="screen-btn flex flex-end">
            <div class="screen-btn-reset tcenter cursor" @click="operate('reset')">
              重置
            </div>
            <div class="screen-btn-save tcenter cursor" @click="operate('save')">
              确定
            </div>
          </div>
        </div>
      </div>
      <!-- 搜索后排序 end -->

      <!-- 女装卡片 start -->
      <div class="home-index-main-card-female" v-if="currentIndex !== 0 && !searchFlag">
        <div class="home-index-main-card-female-item cursor" 
          v-for="(item,index) in femaleArr" 
          :key="index"
          @click="cardHandle(item)">
          <div class="home-index-main-card-female-item-img">
            <img :src="item.img" alt="">
          </div>
          <div class="home-index-main-card-female-item-title">
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 女装卡片 end -->

      <!-- 为你推荐 start -->
      <div class="home-index-main-sell">
        <h4 class="home-index-main-sell-title" v-if="!searchRecommendFlag">为你推荐</h4>
        <div class="home-index-main-sell-box" v-if="!searchRecommendFlag">
          <productCard ref="productCard" :sellArr="sellArrRecommend" v-if="sellArrRecommend.length"></productCard>
          <div v-else class="empty">没有更多商品了</div>
        </div>
        <div  class="home-index-main-sell-box" v-if="searchRecommendFlag">
          <productCard ref="productCard" :sellArr="sellArr" v-if="sellArr.length"></productCard>
          <div v-else class="empty">没有更多商品了</div>
        </div>
      </div>
      <!-- 为你推荐 end -->
    </div>
  </div>
</template>

<script>
import productCard from '@/components/shoppingMall/productCard.vue'
import { wanlshopList, wanlshopInit, wanlshopCategory, wanlshoSearchListy, wanlshoSetSearch,wanlshopProductLists, 
  getContentImage, scoreGoodsList  } from '../../../api/shoppingMall'
export default {
  components: {
    productCard
  },
  data() {
    return {
      categoryArr: [{
        name: '推荐',
        type: '1'
      }],
      currentIndex: 0,
      moduleArr: [{
        img: require('@/assets/images/module-red.png'),
        path: 'coupons',
      },{
        img: require('@/assets/images/module-category.png'),
        path: 'category',
      },{
        img: require('@/assets/images/module-cart.png'),
        path: 'shop-cart',
      },{
        img: require('@/assets/images/module-order.png'),
        path: 'my-order',
      },{
        img: require('@/assets/images/module-integral.png'),
        path: 'my-points',
      },{
        img: require('@/assets/images/module-address.png'),
        path: 'address',
      },{
        img: require('@/assets/images/module-after.png'),
        path: 'after-sale-service',
      },{
        img: require('@/assets/images/module-department.png'),
        path: 'followed-store',
      },{
        img: require('@/assets/images/module-collect.png'),
        path: 'my-collection',
      },{
        img: require('@/assets/images/module-shopping.png'),
        path: 'points-shop',
      }],
      sellArr: [],
      sellArrRecommend: [],
      currentMoreIndex: 0,
      historyArr: [],
      searchArr: [],
      focusFlag: false,
      searchFlag: false,
      sortArr: [{
        name: '综合',
        type: 1,
        sort: 'weigh',
        order: 'desc',
      },{
        name: '销量',
        type: 2,
        sort: 'sales',
        order: 'desc',
      },{
        name: '新上架',
        type: 3,
        sort: 'createtime',
        order: 'desc',
      },{
        name: '价格',
        type: 4,
        sort: 'price',
        order: 'desc',
      },{
        name: '筛选',
        type: 5,
        sort: 'weigh',
        order: 'desc',
      }],
      currentSortIndex: 0,
      search: '',
      femaleArr: [],
      visible: false,
      screenFlag: false,
      searchRecommendFlag: false,
      minVal: '', // 最小值
      maxVal: '', // 最大值
      sendGoodAddress: '', // 发货地
      lunbotu: [],
      city: [
        { name: '北京', choice: false },
        { name: '天津', choice: false },
        { name: '河北', choice: false },
        { name: '山西', choice: false },
        { name: '内蒙古', choice: false },
        { name: '山东', choice: false },
        { name: '江苏', choice: false },
        { name: '上海', choice: false },
        { name: '浙江', choice: false },
        { name: '安徽', choice: false },
        { name: '福建', choice: false },
        { name: '江西', choice: false },
        { name: '河南', choice: false },
        { name: '湖南', choice: false },
        { name: '湖北', choice: false },
        { name: '广东', choice: false },
        { name: '广西', choice: false },
        { name: '海南', choice: false },
        { name: '辽宁', choice: false },
        { name: '吉林', choice: false },
        { name: '黑龙江', choice: false },
        { name: '四川', choice: false },
        { name: '贵州', choice: false },
        { name: '云南', choice: false },
        { name: '重庆', choice: false },
        { name: '宁夏', choice: false },
        { name: '青海', choice: false },
        { name: '陕西', choice: false },
        { name: '甘肃', choice: false },
        { name: '新疆', choice: false },
        { name: '西藏', choice: false },
        { name: '香港', choice: false },
        { name: '澳门', choice: false },
        { name: '台湾', choice: false }
      ],
      currentIndexCity: 0, // 当前选择城市下标为北京
      cityFlag: false
    }
  },
  created () {
    this.search = this.$route.query.search ? this.$route.query.search : ''
    this.getContentImage()
    this.getWanlshopInit() // 商品类目
    this.getWanlshopList() // 商品列表
    this.getScoreGoodsList() // 为你推荐商品
    if (this.search) {
      this.operate('search')
    }
  },
  mounted () {
    let _this = this
    document.addEventListener("click", this.blurHandle)
  },
  methods: {
    /**
     * @Author: angel~dongsanling
     * @description: 选择城市
     */    
    selectCity () {
      this.cityFlag = true
    },
    /**
     * @Author: angel~dongsanling
     * @description: 当前选择的城市
     * @param {*} index 当前选择的下标
     * @param {*} data 当前数据
     */    
    handleCity (index, data) {
      this.currentIndexCity = index
      this.sendGoodAddress = data.name
      this.cityFlag = false
    },
    /**
     * @Author: angel~dongsanling
     * @description: 商城轮播图
     */    
    getContentImage () {
      this.lunbotu = []
      getContentImage({ 'name': 'pc_shop_banner' }).then(res => {
        if (res.code == 1) {
          if (res.data.length) {
            res.data.map((item, index) => {
              item.img = `${this.$store.getters.imgeCDN}${item.images}`
            })
          }
          this.lunbotu = res.data
        }
      })
    },
    cardHandle (data) {
      this.search = ''
      this.searchType = false
      this.wanlshopProductLists('category',data)
    },
    /**
     * @Author: angel~dongsanling
     * @description: 为你推荐商品
     */    
    getScoreGoodsList () {
      this.sellArr = []
      this.sellArrRecommend = []
      scoreGoodsList({ type: 0 }).then(res => {
        if (res.code == 1) {
          if (res.data) {
            res.data.map((item, index) => {
              item.img = `${this.$store.getters.imgeCDN}${item.image}`
            })
          }
          this.sellArrRecommend = res.data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 筛选后调用接口
     */    
    wanlshopProductLists (type, data) {
      this.sellArrRecommend = []
      this.sellArr = []
      let params = {
        search: this.search,
        sort: this.sortArr[this.currentSortIndex].sort,
        order: this.search ? 'asc' : 'desc',
        page: 1,
        filter: {},
        op: {},
        type: 'goods'
      }
      if (type == 'category') {
        params.filter['category_id'] = data.id
        params.op['category_id'] = 'in'
      }
      if (this.minVal && this.maxVal) {
        params.filter['price'] = `${this.minVal} , ${this.maxVal}`
        params.op['price'] = 'BETWEEN'
      } else {
        delete params.filter.price;
				delete params.op.price;
      }
      if (this.sendGoodAddress) {
        params.filter['shop.city'] = '%' + this.sendGoodAddress + '%' 
        params.op['shop.city'] = 'LIKE'
      } else {
        delete params.filter['shop.city']
				delete params.op['shop.city']
      }
      params.filter = params.filter ? JSON.stringify(params.filter) : {}
      params.op = params.op ? JSON.stringify(params.op) : {}
      params.search = params.search ? JSON.stringify(params.search) : ''
      wanlshopProductLists(params).then(res => {
        if (res.code == 1) {
          if (res.data.data.length) {
            res.data.data.map((item, index) => {
              item.img = `${this.$store.getters.imgeCDN}${item.image}`
            })
          }
          if (this.search || type == 'searchHandle') {
            this.sellArr = res.data.data
          } else {
            this.sellArrRecommend = res.data.data
          }
        }
      })
    },

    // 历史搜索
    historyList() {
      let list = JSON.parse(localStorage.getItem('search:history'))
      this.historyArr = list
    },

    // 清空历史
    deleteHistory() {
      localStorage.setItem('search:history', JSON.stringify([]))
      this.historyList()
    },
    /**
     * @Author: angel~dongsanling
     * @description: 热门搜索
     */    
    hotSearchList () {
      this.searchArr = []
      wanlshoSearchListy().then(res => {
        if (res.code == 1) {
          this.searchArr = res.data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 商品热门搜索
     */    
    setSearch (type) {
      wanlshoSetSearch({ keywords: this.search ? JSON.stringify(this.search) : '' }).then(res => {
        if (res.code == 1) {
          this.wanlshopProductLists(type)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取商品二级类目
     */    
    getWanlshopCategory (id) {
      this.sellArr = []
      this.sellArrRecommend = []
      wanlshopCategory({id: id, page: 1}).then(res => {
        if (res.code == 1) {
          if (res.data.goods.data.length) {
            res.data.goods.data.map((item, index) => {
              item.img = `${this.$store.getters.imgeCDN}${item.image}`
            })
          }
          // this.sellArr = res.data.goods.data
          this.sellArrRecommend = res.data.goods.data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取商品类目
     */    
    getWanlshopInit () {
      this.categoryArr = [{
        name: '推荐',
        type: '1'
      }]
      wanlshopInit({version: '1.1.9'}).then(res => {
        if (res.code == 1) {
          this.categoryArr= this.categoryArr.concat(res.data.modulesData.categoryModules) 
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取商品列表
     */    
    getWanlshopList () {
      this.sellArr = []
      this.sellArrRecommend = []
      wanlshopList({ page: 1 }).then(res => {
        if (res.code == 1) {
          if (res.data.data.length) {
            res.data.data.map((item, index) => {
              item.img = `${this.$store.getters.imgeCDN}${item.image}`
            })
          }
          this.sellArr = res.data.data
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 点击卡片
     * @param {*} item 当前选择数据
     */    
    cardClick(item) {
      this.$router.push({path: item.path})
      this.searchType = false
    },
    /**
     * @Author: angel~dongsanling
     * @description: 搜索点击内容
     * @param {*} index 当前选中的index
     */    
    searchHandle (data) {
      this.search = data.keywords
      this.focusFlag = false
      this.searchType = true
      this.setSearch('searchHandle')
      let list = JSON.parse(localStorage.getItem('search:history')) ? JSON.parse(localStorage.getItem('search:history')) : []
      list.push({keywords: data.keywords})
      localStorage.setItem('search:history', JSON.stringify(list))
    },
    /**
     * @Author: angel~dongsanling
     * @description: 排序点击选中
     * @param {*} index 当前选中的index
     */    
    sortHandle (index,data) {
      this.currentSortIndex = index
      if (data.type === 5){
        this.screenFlag = true
      } else {
        this.screenFlag = false
      }
      if (this.searchType) {
        this.wanlshopProductLists('searchHandle')
      } else {
        this.wanlshopProductLists()
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 操作
     * @param {*} type 根据类型判断是那些方法
     */    
    operate (type) {
      switch (type) {
        case 'search':
          this.searchFlag = true
          this.searchRecommendFlag = true
          this.searchHandle ({ keywords: this.search }) 
          this.searchType = true
          break
        case 'reset':
          // this.screenFlag = false
          this.maxVal = ''
          this.minVal = ''
          this.sendGoodAddress = ''
          break
        case 'save':
          this.screenFlag = false
          if (this.searchType) {
            this.wanlshopProductLists('searchHandle')
          } else {
            this.wanlshopProductLists()
          }
          break
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取焦点
     * @param {*} val
     */    
    focusHandle (val){
      this.focusFlag = true
      this.historyList()
      this.hotSearchList()
    },
    /**
     * @Author: angel~dongsanling
     * @description: 失去焦点
     * @param {*} val
     */    
    blurHandle (e) {
      let _this = this;
     	if (_this.focusFlag == true){
        _this.focusFlag = false;
        _this.searchType = false
     	}
    },
    /**
     * @Author: angel~dongsanling
     * @description: 更多里面选中多个
     * @param {*} index 当前选中的index
     */    
    selectCategoryHandle (index, data) {
      this.currentMoreIndex = index
      this.currentIndex = index
      this.visible = false
      this.searchRecommendFlag = false
      this.searchFlag = false
      this.search = ''
      this.searchType = false
      if (index != 0) {
        if (data.childlist.length) {
          data.childlist.map((item, index) => {
            item.img = `${this.$store.getters.imgeCDN}${item.image}`
          })
        }
        this.femaleArr = data.childlist
        this.getWanlshopCategory(data.id)
      } else {
        this.getWanlshopList()
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 切换分类搜索
     * @param {*} index 当前选中的index
     */    
    tabCategory (index,data) {
      this.currentIndex = index
      this.searchRecommendFlag = false
      this.search = ''
      this.searchFlag = false
      this.searchType = false
      if (index != 0) {
        if (data.childlist.length) {
          data.childlist.map((item, index) => {
            item.img = `${this.$store.getters.imgeCDN}${item.image}`
          })
        }
        this.femaleArr = data.childlist
        this.getWanlshopCategory(data.id)
      } else {
        this.getScoreGoodsList() // 推荐商品列表
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home-index{
  background: #F7F7F7;
  .home-index-category{
    width: 100%;
    height: 170px;
    padding-right: 30px;
    background: #FFFFFF;
    border-radius: 1px;
    .home-index-category-box{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .home-index-category-box-left{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: auto;
        .home-index-category-box-item{
          height: 50px;
          display: flex;
          align-items: center;
          padding: 0px 44px;
          color: #333;
          font-size: 18px;
          font-weight: 600;
          white-space: nowrap;
          &.active{
            height: 50px;
            padding: 0px 44px;
            color: #fff;
            background: #2676F7;
            border-radius: 1px;
          }
        }
      }
      .home-index-category-box-right{
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  .home-index-banner{
    width: 100%;
    height: 754px;
    /deep/.el-carousel--horizontal{
      height: 100%;
      .el-carousel__container{
        height: 100%;
      }
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  .home-index-main{
    
    margin-top: -48px;
    .home-index-main-search{
      margin-bottom: 60px;
      position: relative;
      z-index: 100;
      .home-index-main-search-box{
        height: 96px;
        display: flex;
        background: #FFFFFF;
        .search-input{
          height: 96px;
          color: #999;
          font-size: 28px;
          flex: 1;
          margin-right: 0px !important;
          /deep/.el-input__inner{
            height: 96px;
            padding-left: 40px;
            border: 1px solid #2676F7 !important;
          }
        }
        .search-btn{
          width: 256px;
          height: 96px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 32px;
          font-weight: 600;
          background: #2676F7;
        }
      }
      .home-index-main-search-content{
        width: 1200px;
        min-height: 256px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 9px 19px 0px rgba(0,0,0,0.14);
        border-radius: 0px 0px 10px 10px;
        display: none;
        position: absolute;
        left: 0px;
        top: 96px;
        z-index: 100;
        .home-index-main-search-content-history{
          padding: 30px 45px 0px 30px;
          .home-index-main-search-content-history-title{
            .home-index-main-search-content-history-left{
              font-size: 20px;
              font-weight: 500;
            }
          }
          .home-index-main-search-content-history-content{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 20px;
            .home-index-main-search-content-history-content-item{
              padding: 9px 34px;
              margin-right: 22px;
              background: #FFFFFF;
              border-radius: 25px;
              border: 1px solid #E9E9E9;
              margin-bottom: 20px
            }
          }
        }
        .home-index-main-search-content-hot{
          padding-left: 30px;
          padding-right: 30px;
          margin-top: 20px;
          .home-index-main-search-content-hot-title{
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
            img{
              margin-right: 8px;
            }
          }
          .home-index-main-search-content-hot-content{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .home-index-main-search-content-hot-content-item{
              padding: 9px 34px;
              margin-right: 22px;
              background: #FFFFFF;
              border-radius: 25px;
              border: 1px solid #E9E9E9;
              margin-bottom: 20px;
            }
          }
        }
        &.active{
          display: block;
        }
      }
    }
    .home-index-main-card{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .home-index-main-card-item{
        margin-bottom: 40px;
        cursor: pointer;
        img{
          width: 232px;
          height: 217px;
        }
      }
    }
    .home-index-main-sort{
      width: 100%;
      height: 116px;
      padding: 0px 50px;
      background: #FFFFFF;
      border-radius: 4px;
      margin-bottom: 30px;
      position: relative;
      .home-index-main-sort-box{
        flex: 1;
        height: 100%;
        padding-right: 80px;
        box-sizing: border-box;
        .home-index-main-sort-box-item{
          font-size: 20px;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &.active{
            color: #2676F7;
            font-size: 20px;
            font-weight: 500;
            position: relative;
          }
          &.active::after{
            content: '';
            width: 32px;
            height: 20px;
            background: url(../../../assets/images/active-bottom-icon.png) no-repeat center center;
            background-size: 80%; 
            position: absolute;
            left: 50%;
            bottom: -15px;
            transform: translateX(-50%);
          }
        }
      }
      .home-index-main-sort-img{
        width: 30px;
      }
      .screen{
        width: 337px;
        height: 226px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 9px 19px 0px rgba(0,0,0,0.14);
        border-radius: 0px 0px 10px 10px;
        position: absolute;
        right: 26px;
        top: 110px;
        box-sizing: border-box;
        z-index: 100;
        .screen-city{
          padding-bottom: 15px;
          border-bottom: 1px solid #F0F0F0;
          .screen-city-label{
            font-weight: 500;
            font-size: 16px;
            color: #333333;
          }
          .screen-city-value{
            font-weight: 400;
            font-size: 14px;
            color: #999999;
            position: relative;
            .screen-city-box{
              width: 400px;
              height: 400px;
              overflow: auto;
              padding: 10px 10px;
              position: absolute;
              right: 0px;
              top: 30px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;
              background: #fff;
              z-index: 1000;
              .screen-city-box-item{
                width: 110px;
                height: 40px;
                line-height: 40px;
                background: #f8f8f8;
                text-align: center;
                border-radius: 4px;
                margin-right: 10px;
                margin-bottom: 10px;
                &.active{
                  background: #2676F7;
                  color: #fff;
                }
              }
              .screen-city-box-item:nth-child(3n){
                margin-right: 0px;
              }
            }
          }
        }
        .screen-price{
          margin-bottom: 30px;
          margin-top: 14px;
          .screen-price-label{
            font-weight: 500;
            font-size: 16px;
            color: #333333;
            margin-bottom: 10px;
          }
          .screen-price-value{
            /deep/.el-input{
              .el-input__inner{
                width: 130px;
                height: 32px;
              }
            }
          }
        }
        .screen-btn{
          .screen-btn-reset{
            width: 70px;
            height: 40px;
            line-height: 40px;
            font-weight: 500;
            font-size: 15px;
            color: #333333;
            background: #FFFFFF;
            border-radius: 1px;
            border: 1px solid #DDDDDD;
            margin-right: 15px;
          }
          .screen-btn-save{
            width: 100px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            font-weight: 500;
            font-size: 15px;
            background: #2676F7;
            border-radius: 1px;
          }
        }
      }
    }
    .home-index-main-card-female{
      padding: 40px 30px;
      background: #FFEFE8;
      border-radius: 20px;
      border: 2px solid #FFFFFF;
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .home-index-main-card-female-item{
        width: 210px;
        margin-right: 21.5px;
        margin-top: 20px;
        .home-index-main-card-female-item-img{
          width: 210px;
          height: 200px;
          margin-bottom: 20px;
          img{
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
        .home-index-main-card-female-item-title{
          font-size: 18px;
          text-align: center;
        }
      }
      .home-index-main-card-female-item:nth-child(5n){
        margin-right: 0px !important;
      }
      .home-index-main-card-female-item:first-child{
        margin-top: 0px !important;
      }
    }
    .home-index-main-sell{
      padding-bottom: 60px;
      .home-index-main-sell-title{
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        position: relative;
        margin-bottom: 30px;
      }
      .home-index-main-sell-title::after{
        content: '';
        width: 110px;
        height: 4px;
        background: #2676F7;
        border-radius: 2px;
        position: absolute;
        left: 5px;
        bottom: -5px;
      }
      .home-index-main-sell-box{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .empty{
          width: 100%;
          font-size: 16px;
          text-align: center;
        }
      }
    }
  }

  ::-webkit-scrollbar{
    display: none;
  }
}
</style>