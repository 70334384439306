<template>
  <div class='address-edit'>
    <div class="page">
      <div class="page-content">
        <crumbsVue :navs="navs"></crumbsVue>
        <div class="content">
          <div class="page-title">{{ pageTitle }}</div>
          <el-row class="row">
            <el-col :span="2" class="title">
              <div class="require">*</div> <div>联系人</div>
            </el-col>
            <el-col :span="8">
              <el-input placeholder="请输入姓名" v-model="params.name"></el-input>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="2" class="title">
              <div class="require">*</div> <div>手机号码</div>
            </el-col>
            <el-col :span="8">
              <el-input placeholder="请输入手机号码" v-model="params.mobile"></el-input>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="2" class="title">
              <div class="require">*</div> <div>所在地区</div>
            </el-col>
            <el-col :span="8">
              <el-cascader
                v-model="params.area"
                :props="optionProps"
                :options="areaOption"
                @change="handleChange">
              </el-cascader>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="2" class="title">
              <div class="require">*</div> <div>详细地址</div>
            </el-col>
            <el-col :span="8">
              <el-input placeholder="请输入详细地址" v-model="params.address"></el-input>
            </el-col>
          </el-row>
          <el-row class="row mb200">
            <el-col :span="2"></el-col>
            <el-col :span="8" class="default-address">
              <div v-if="!params.isDefaultAddress" class="un-select" @click="setDefaultAddress()"></div>
              <img v-else src="../../../assets/images/shop-selected.png" @click="setDefaultAddress()" alt="">
              <div @click="setDefaultAddress()">设为默认收货地址</div>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="2"></el-col>
            <el-col :span="8" class="default-address">
              <div class="btn" @click="confirm()">确定</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbsVue from '@/components/shoppingMall/crumbs.vue';
import { areaList, addAddress } from '@/api/shoppingMall'

export default {
  components: {
    crumbsVue,
  },
  props: {
    navs: {
      type: Array,
      default: function() {
        return [
          {
            title: '首页',
            path: 'home'
          }, {
            title: '收货地址',
          },
        ]
      },
    }
  },
  data() {
    return {
      pageTitle: '新增地址',
      type: 'add',
      optionProps: {
        value: 'id',
        label: "name",
        children: "children",
      },
      areaOption: [],
      selectedArea: [],
      params: {
        name: '',
        mobile: '',
        area: '',
        address: '',
        isDefaultAddress: false
      },
    }
  },
  methods: {
    setDefaultAddress() {
      this.params.isDefaultAddress = !this.params.isDefaultAddress
      this.$forceUpdate()
    },
    getArea() {
      areaList().then(res=>{
        if(res.code == 1) {
          let replacedObject = this.replaceKey(res.data, 'city', 'children');
          replacedObject = this.replaceKey(replacedObject, 'area', 'children');
          this.areaOption = replacedObject

          if(this.type == 'edit') {
            this.filterArea(this.params.adcode)
          }
        }
      })
    },
    replaceKey(obj, oldKey, newKey) {
      if (typeof obj !== 'object' || obj === null) {
        return obj; // 不是对象则返回原值
      }
    
      // 初始化一个新对象
      let newObj = Array.isArray(obj) ? [] : {};
    
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === oldKey) {
            newObj[newKey] = this.replaceKey(obj[key], oldKey, newKey); // 递归替换key
          } else {
            newObj[key] = this.replaceKey(obj[key], oldKey, newKey); // 递归复制其它属性
          }
        }
      }
    
      return newObj;
    },
    handleChange(value) {
      if(value.length == 3) {
        let id = value[2]
        for(let i = 0; i < this.areaOption.length; i++) {
          let pro = this.areaOption[i]
          for(let j = 0; j < pro.children.length; j++) {
            let city = pro.children[j]
            for(let m = 0; m < city.children.length; m++) {
              let area = city.children[m]
              if(area.id == id) {
                this.selectedArea = []
                this.selectedArea.push(pro)
                this.selectedArea.push(city)
                this.selectedArea.push(area)
                break
              }
            }
          }
        }
      }
    },
    filterArea(adcode) {
      for(let i = 0; i < this.areaOption.length; i++) {
        let pro = this.areaOption[i]
        for(let j = 0; j < pro.children.length; j++) {
          let city = pro.children[j]
          for(let m = 0; m < city.children.length; m++) {
            let area = city.children[m]
            if(area.id == adcode) {
              // this.params.area = [pro.zip, city.zip, area.zip]
              this.params.area = [pro.id, city.id, area.id]
              this.selectedArea.push(pro)
              this.selectedArea.push(city)
              this.selectedArea.push(area)
              break
            }
          }
        }
      }
    },
    confirmCheck() {
      if(!this.params.name) {
        this.$message.warning('请输入姓名')
        return false
      }
      if(!this.params.mobile) {
        this.$message.warning('请输入手机号码')
        return false
      }
      if(!this.params.area) {
        this.$message.warning('请选择所在地区')
        return false
      }
      if(!this.params.address) {
        this.$message.warning('详细地址必填')
        return false
      }
      return true
    },
    confirm() {
      if(!this.confirmCheck()) return
      let params = {
        data: {
          adcode: this.selectedArea[2].id,
          address: this.params.address,
          address_full: this.params.address,
          address_name: '',
          city: this.selectedArea[1].name,
          citycode: this.selectedArea[2].code,
          country: '中国',
          default: this.params.isDefaultAddress ? 1 : 0,
          district: this.selectedArea[2].name,
          formatted_address: `${this.selectedArea[0].name}${this.selectedArea[1].name}${this.selectedArea[2].name}`,
          mobile: this.params.mobile,
          name: this.params.name,
          province: this.selectedArea[0].name
        },
        type: this.type
      }

      if(this.type == 'edit') {
        params.data.id = this.params.id
      } 
      addAddress(params).then(res=>{
        if(res.code == 1) {
          this.$message.success('保存成功')
          this.$router.back()
        }
      })
    }
  },
  created() {
    let item = this.$route.query.item
    if(item) {
      this.pageTitle = '编辑地址'
      this.params = JSON.parse(JSON.stringify(item))
      this.params.isDefaultAddress = item.default == '1' ? true : false
      this.type = 'edit'
    }

    this.getArea()
  }
}
</script>

<style lang="less" scoped>
.address-edit {
  .content {
    margin-bottom: 60px;
    padding: 30px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #F1F1F1;

    .page-title {
      margin-bottom: 43px;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
    }
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .title {
        font-size: 16px;
        color: #666666;
        display: flex;
        align-items: center;
        .require {
          color: #FF0808;
        }
      }

      .default-address {
        font-size: 14px;
        color: #585858;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        .un-select {
          width: 16px;
          height: 16px;
          margin-right: 12px;
          background: #FFFFFF;
          border: 1px solid #EDEDED;
          cursor: pointer;
        }

        img {
          width: 16px;
          height: 16px;
          margin-right: 12px;
          cursor: pointer;
        }

        .btn {
          width: 68px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          background: #2676F7;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      
    }

    .mb200 {
      margin-bottom: 200px;
    }
  }

  /deep/.el-cascader {
    width: 100%;
  }
}
</style>