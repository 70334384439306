<template>
  <div class='member-center'>
    <div class="page-header mb20">
      <div class="page-header-content page-header-title">
        <div class="back" @click="back()">返回</div>
        会员中心
      </div>
    </div>
    <div class="page">
      <div class="page-content">
        <div class="content">
          <div class="top">
            <img :src="avatar" alt="">
            <div class="detail">
              <div class="name">{{ userInfo.nickname }}</div>
              <div class="company-name">{{userInfo.companyInfo ? userInfo.companyInfo.company_name : ''}}</div>
              <div class="memeber-status" v-if="!userInfo.is_vip">暂未开通VIP</div>
              <div class="memeber-status" v-else>{{ userInfo.vip_expire_time*1000 | toDay }} 到期</div>
            </div>
          </div>
          <div class="center">
            <div class="vip-item" :class="{'vip-active': vipActiveIndex==item.id}" v-for="(item, index) in VIPList" :key="index" @click="VIPItemClick(item)">
              <div class="time">{{ item.month }}个月</div>
              <div class="amount"> <span>￥</span> <span>{{ item.price }}</span> </div>
              <div class="free-time">免费发布{{ item.month }}个月</div>
            </div>
          </div>
          <div class="bottom-btn" @click="pay()">立即充值</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { VipPrices } from '@/api/company'
import { userInfo } from '@/api/userInfo'

export default {
  data() {
    return {
      userInfo: {},
      vipActiveIndex: 0,
      VIPList: [],
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    initUserInfo() {
      userInfo().then(res=>{
        if(res.code == 1) {
          this.userInfo = res.data.userinfo
        }
      })
    },
    VIPItemClick(item) {
      this.vipActiveIndex = item.id
    },
    getVipPrices() {
      VipPrices().then(res=>{
        if(res.code == 1) {
          this.VIPList = res.data
          if(this.VIPList.length) {
            this.vipActiveIndex = this.VIPList[0].id
          }
        }
      })
    },
    pay() {
      let url = `https://app.digginggoldroad.com/api/company/order?vip_prices_id=${this.vipActiveIndex}&method=web&user_id=${this.userInfo.id}`
      window.open(url)
    }
  },
  computed: {
    avatar() {
      if(this.userInfo.avatar) {
        return this.$store.getters.imgeCDN + this.userInfo.avatar
      }
      return ''
    },
  },
  created() {
    this.initUserInfo()
    this.getVipPrices()
  }
}
</script>

<style lang="less" scoped>
.member-center {
  .content {
    width: 100%;
    padding: 40px 133px;
    background: #FFFFFF;
    border-radius: 13px 13px 13px 13px;
    margin-bottom: 30px;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 67px;
      img{
        width: 150px;
        height: 150px;
        margin-right: 60px;
        border-radius: 10px;
      }
      .detail{
        color: #222222;
        .name {
          font-weight: 500;
          font-size: 30px;
          margin-bottom: 20px;
        }
        .company-name{
          font-size: 23px;
          margin-bottom: 20px;
        }
        .memeber-status {
          font-size: 23px;
          color: #999999;
        }
      }
    }
    .center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .vip-item {
        width: 288px;
        padding: 27px 27px 30px;
        border-radius: 20px 20px 20px 20px;
        border: 1px solid #E8E8E8;
        text-align: center;
        cursor: pointer;
        .time {
          font-weight: 500;
          font-size: 27px;
          color: #222222;
          margin-bottom: 17px;
        }
        .amount {
          color: #F33B50;
          font-weight: bold;
          margin-bottom: 20px;

          span:nth-child(1){
            font-size: 33px;
          }
          span:nth-child(2){
            font-size: 53px;
          }
        }

        .free-time {
          font-size: 23px;
          color: #2676F7;
        }
      }
      .vip-active{
        background: rgba(233,167,61,0.08);
        border: 1px solid #E9A73D;
      }
    }
    .bottom-btn {
      width: 100%;
      height: 65px;
      line-height: 65px;
      margin-top: 77px;
      text-align: center;
      font-weight: 500;
      font-size: 23px;
      color: #FFFFFF;
      background: #2676F7;
      border-radius: 13px 13px 13px 13px;
      cursor: pointer;
    }
  }
}

</style>
