<template>
  <div class='seeker-layout'>
    <div class="main">
      <div class="header">
        <div class="header-content">
          <div class="left" @click="isShowChangeCityDialog=true">
            <img src="../../assets/images/location-icon.png" alt="">
            <div>{{ currentCity.name }}</div>
          </div>
          <div class="center">
            <div class="item" :class="{ 'active': item.index == activeIndex, 'mr0': index == headerTabs.length - 1 }"
              v-for="(item, index) in headerTabs" :key="index" @click="handleTab(item)">{{ item.title }}</div>
          </div>
          <div class="right">
            <div class="av" @click="goPersonalCenter()">
              <img class="av" :src="avUrl" alt="">
            </div>
            <div class="set">
              <el-popover
                ref="setpopover"
                placement="bottom"
                popper-class="custom-popover-myself"
                class="custom-popover"
                trigger="click">
                <div class="cont">
                  <div class="item" v-for="(item, index) in setArr" :key="index"
                    @click="operate('setChange', item, index)">
                    <img :src="item.icon" alt="">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <img slot="reference" src="../../assets/images/set-icon.png" alt="">
              </el-popover>
            </div>
            <div class="set">
              <el-popover
                ref="morepopover"
                placement="bottom"
                popper-class="custom-popover-myself"
                class="custom-popover"
                trigger="click">
                <div class="cont">
                  <div class="item" v-for="(item, index) in moreMenuArr" :key="index"
                    @click="operate('setChange', item, index)">
                    <img :src="item.icon" alt="">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
                <img class="mr0" slot="reference" src="../../assets/images/more-icon.png" alt="">
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view class="router-view" ref="routerView" />
    <div class="footer">
      <div class="content">
        <div>
          <span @click="goAgreement(3)">关于我们</span>
          <span @click="goAgreement(1)">用户协议</span>
          <span @click="goAgreement(2)">隐私政策</span>
          <span @click="goAgreement(4)">平台资质</span>
        </div>
        <div class="copy-right">Copyright @2021 掘金之路股份有限公司 Al Right Reserved.粤ICP备757132413号-1</div>
      </div>
    </div>

    <cityDialog v-if="isShowChangeCityDialog" :currentCity="currentCity" @closed="isShowChangeCityDialog=false" @selected="selectedCity"></cityDialog>
  </div>
</template>

<script>
import cityDialog from '@/components/cityDialog.vue'
import { TUILogin } from "@tencentcloud/tui-core";

export default {
  components: {
    cityDialog
  },
  data() {
    return {
      isShowChangeCityDialog: false,
      currentCity: {
        id: 2297,
        name: '三亚市'
      },
      activeIndex: 0,
      headerTabs: [
        {
          index: 0,
          title: '首页',
          path: '/'
        }, {
          index: 1,
          title: '职位',
          path: 'job'
        }, {
          index: 2,
          title: '消息',
          path: 'message'
        }, {
          index: 3,
          title: '职场资讯',
          path: 'workplacenews'
        }, {
          index: 4,
          title: '职场论坛',
          path: 'workplaceforum',
        }, {
          index: 5,
          title: '商城',
          path: 'shoppingMall/home',
          type: 'shop'
        }
      ],
      setArr: [{
        name: '账号安全',
        path: 'accountSecurity',
        icon: require('@/assets/images/set-acount.png')
      }, {
        name: '关于我们',
        path: 'agreement?type=3',
        icon: require('@/assets/images/set-about.png')
      }, {
        name: '用户协议',
        path: 'agreement?type=1',
        icon: require('@/assets/images/set-user.png')
      }, {
        name: '隐私政策',
        path: 'agreement?type=2',
        icon: require('@/assets/images/set-lock.png')
      }, {
        name: '平台资质',
        path: 'agreement?type=4',
        icon: require('@/assets/images/set-platform.png')
      }, {
        name: '退出登录',
        // path: '/preferences',
        icon: require('@/assets/images/set-exit.png'),
        type: 'outLogin'
      }],
      moreMenuArr: [
        {
          name: '在线简历',
          path: 'onlineResume',
          icon: require('@/assets/images/file-icon.png')
        },{
          name: '我的订单',
          path: 'myorder',
          icon: require('@/assets/images/order-icon.png')
        },{
          name: '我的课程',
          path: 'mylesson',
          icon: require('@/assets/images/lesson-icon.png')
        },{
          name: '我的推广',
          path: 'mypromotion',
          icon: require('@/assets/images/promotion-icon.png')
        },{
          name: '实名认证',
          path: 'authentication',
          icon: require('@/assets/images/certificate-icon.png')
        },{
          name: '常用语',
          path: 'commonphrases',
          icon: require('@/assets/images/common-phrases-icon.png')
        },{
          name: '我要开店',
          path: 'https://app.digginggoldroad.com/index/user/login.html',
          icon: require('@/assets/images/open-shop-icon.png'),
          type: 'openShop'
        },
        // {
        //   name: '绑定微信',
        //   path: '',
        //   icon: require('@/assets/images/wechat-icon.png')
        // },
      ]
    }
  },
  methods: {
    handleTab (item) {
      this.activeIndex = item.index
      if(item.type && item.type == 'shop') {
        let path = `${window.location.origin}/${item.path}`
        window.open(path, '_blank')
      } else {
        this.$router.push(item.path)
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 操作方法
     * @param {*} type 根据类型判断是谁点击的事件
     * @param {*} data 当前点击传进去的数据
     */
    operate (type, data) {
      switch (type) {
        case 'setChange': // 设置里面点击
          if (data.type == 'outLogin'){
            localStorage.clear()
            this.$router.push({
              path: '/login'
            })
            TUILogin.logout().then(res=>{
              // debugger
            }).catch(err=>{
              // debugger
            })
          } else if (data.type == 'openShop') {
            window.open(data.path, '_blank')
          } else {
            this.$router.push({
              path: data.path
            })
          }
          break
      }

      this.$refs.setpopover.doClose()
      this.$refs.morepopover.doClose()
    },
    goPersonalCenter(){
      this.$router.push('personal-center')
    },
    selectedCity(item) {
      this.currentCity = item
      this.isShowChangeCityDialog = false
      this.setLocation()
      if(window.location.pathname == '/job') {
        this.$refs.routerView.getDataList()
      }
    },
    setLocation() {
      localStorage.setItem('jjzl_jobCityId', JSON.stringify(this.currentCity))
    },
    goAgreement(type) {
      this.$router.push('agreement?type=' + type)
    }
  },
  created() {
    let cityItemString = localStorage.getItem('jjzl_jobCityId')
    if(cityItemString) {
      this.currentCity = JSON.parse(cityItemString)
    }
  },
  computed: {
    avUrl() {
      return this.$store.getters.imgeCDN + this.$store.getters.userInfo.avatar
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        if(to.path === '/job'){
          this.activeIndex = 1
        } else if (to.path === '/message') {
          this.activeIndex = 2
        } else if (to.path === '/workplacenews') {
          this.activeIndex = 3
        } else if (to.path === '/workplaceforum') {
          this.activeIndex = 4
        } 
      },
      deep: true,
      immediate: true// 第一次初始化渲染就可以监听到
    }
  }
}
</script>

<style lang="less" scoped>
.seeker-layout {
  height: 100%;
  .main {
    .header-content {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 23px;
        color: #FFFFFF;
        cursor: pointer;

        img {
          width: 27px;
          height: 27px;
          margin-right: 7px;
        }
      }

      .center {
        display: flex;
        align-items: center;
        font-size: 23px;
        color: #FFFFFF;

        .item {
          cursor: pointer;
          margin-right: 60px;
          border-bottom: 3px solid rgba(0, 0, 0, 0);
        }

        .active {
          font-weight: 600;
          border-bottom: 3px solid white;
        }

        .mr0 {
          margin-right: 0;
        }
      }

      .right {
        display: flex;
        align-items: center;
        cursor: pointer;

        .av {
          width: 33px;
          height: 33px;
          margin-right: 33px;
          border-radius: 17px;
        }

        img {
          width: 30px;
          height: 30px;
          margin-right: 33px;
        }
      }
    }

    .header {
      width: 100%;
      height: 103px;
      background: #2676F7;
      position: sticky;
      top: 0;
      z-index: 100;
      display: flex;
      justify-content: center;
    }
  }

  .router-view{
    min-height: calc(100% - 321px);
    background-color: #F7F7F7;
  }
  .footer{
    width: 100%;
    height: 218px;
    background: #353840;
    display: flex;
    justify-content: center;
    .content{
      width: 1200px;
      padding-top: 63px;
      // background-color: cadetblue;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;

      span{
        margin-right: 53px;
        cursor: pointer;
      }

      .copy-right{
        margin-top: 41px;
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
        opacity: 0.4;
      }
    }
  }
}
// .seeker-layout {
//   height: 100%;

//   .main {
//     .header1 {
//       width: 100%;
//       height: 62px;
//       background: rgba(0, 0, 0, 0.24);
//       position: sticky;
//       top: 0;
//       z-index: 11111;
//       display: flex;
//       justify-content: center;
//     }

//     .header-content {
//       // width: 720px;
//       width: 1440px;
//       height: 100%;
//       // background-color: red;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       .left {
//         display: flex;
//         align-items: center;
//         font-weight: 400;
//         font-size: 14px;
//         color: #FFFFFF;
//         cursor: pointer;

//         img {
//           width: 32px;
//           height: 32px;
//           margin-right: 4px;
//         }
//       }

//       .center {
//         display: flex;
//         align-items: center;
//         font-size: 14px;
//         color: #FFFFFF;

//         .item {
//           cursor: pointer;
//           margin-right: 36px;
//           border-bottom: 2px solid rgba(0, 0, 0, 0);
//         }

//         .active {
//           font-weight: 600;
//           border-bottom: 2px solid white;
//         }

//         .mr0 {
//           margin-right: 0;
//         }
//       }

//       .right {
//         display: flex;
//         align-items: center;
//         cursor: pointer;
//         font-size: 14px;
//         .av {
//           border-radius: 10px;
//         }

//         img {
//           width: 18px;
//           height: 18px;
//           margin-right: 20px;
//         }
//       }
//     }

//     .header {
//       height: 62px;
//       background: #2676F7;
//       position: sticky;
//       top: 0;
//       z-index: 11111;
//       display: flex;
//       justify-content: center;
//     }

//     .top-search {
//       margin-top: -62px;
//       height: 377px;
//       background: url('../../assets/images/home-banner.png') no-repeat center;
//       background-size: 100%;
//       padding-top: 120px;

//       .search-div {
//         display: flex;
//         justify-content: center;
//         margin-top: 60px;

//         .search {
//           width: 720px;
//           height: 48px;
//           background: #FFFFFF;
//           border-radius: 8px;
//           border: 1px solid #2676F7;
//           display: flex;
//           align-items: center;
//           justify-content: space-between;

//           .left {
//             height: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             font-size: 14px;
//             color: #2676F7;
//             border-right: 1px solid #2676F7;
//             cursor: pointer;

//             img {
//               width: 12px;
//               height: 12px;
//               margin-left: 8px;
//             }
//           }

//           .center {
//             width: 470px;
//             height: 100%;
//             font-size: 14px;

//             /deep/.el-input {
//               height: 100%;
//               width: 100%;
//             }

//             /deep/.el-input__inner {
//               width: 100%;
//               height: 100%;
//               padding-left: 24px;
//             }
//           }

//           .w250 {
//             width: 125px;
//           }

//           .search-txt {
//             height: 100%;
//             line-height: 48px;
//             background: #2676F7;
//             font-weight: 600;
//             font-size: 16px;
//             color: #FFFFFF;
//             cursor: pointer;
//             text-align: center;
//             border-radius: 0 7px 7px 0;
//           }
//         }
//       }
//     }

//     .page-header-div{
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       // background-color: yellow;
//       .page-header{
//         background-color: red;
//         width: 720px;
//         height: 52px;
//         line-height: 52px;
//         background-color: white;
//         text-align: center;
//         position: relative;

//         .back{
//           font-size: 12px;
//           color: #222222;
//           cursor: pointer;
//           position: absolute;
//           left: 0;
//         }
//       }
//     }
    

//     .container {
//       background: #F7F7F7;
//       display: flex;
//       justify-content: center;

//       .router-view {
//         width: 720px;
//       }
//     }
//   }
// }
</style>