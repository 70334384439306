<!--
 * @Author: angel~dongsanling
 * @Date: 2024-07-15 15:28:42
 * @Description: 在线简历
-->
<template>
  <div class='online-resume'>
    <div class=" page-header">
      <div class="page-header-content ">
        <span class="back el-icon-setting cursor" @click="back()">返回</span>
        在线简历
        <div class="btn-save cursor" @click="submit()">保存</div>
      </div>
    </div>
    <div class="online-resume-main width1200">
      <div class="online-resume-main-box">
        <!-- 姓名 start -->
        <div class="online-resume-main-box-item" v-for="(item,index) in messageList" :key="index">
          <div class="online-resume-main-box-item-left">
            <div class="online-resume-main-box-item-left-name">
              <span>{{ item.label }}</span>
              <span class="el-icon-edit-outline cursor" @click="operate(item.type,item)"></span>
            </div>
            <div class="online-resume-main-box-item-left-content">
              {{ item.value }}
            </div>
          </div>
          <div class="online-resume-main-box-item-right" v-if="item.url">
            <img :src="item.url" alt="">
          </div>
        </div>
        <!-- 姓名 end -->
        <!-- 工作经历 start -->
        <div class="online-resume-main-box-before-item" v-for="(item,index) in workerList" :key="index">
          <div class="online-resume-main-box-before-item-name">
            <div class="online-resume-main-box-before-item-left">
              {{ item.title }}
            </div>
            <div class="online-resume-main-box-before-item-right cursor" @click="operate(item.type,item)">
              <img src="../../../assets/images/add-before.png" alt="">
            </div>
          </div>
          <div class="online-resume-main-box-before-item-content" v-for="(subItem,subIndex) in item.children" :key="subIndex">
            <div class="online-resume-main-box-before-item-content-left">
              <div class="online-resume-main-box-before-item-content-left-name" v-if="item.type === 'worker'">
                {{ subItem.company_name }}
              </div>
              <div class="online-resume-main-box-before-item-content-left-job" v-if="item.type === 'worker'">
                <span>{{ subItem.position }} 　</span>
                <span>{{ subItem.service_time }}</span>
              </div>
              <div class="online-resume-main-box-before-item-content-left-job" v-if="item.type === 'product'">
                项名称：{{ subItem.company_name }}
              </div>
              <div class="online-resume-main-box-before-item-content-left-work" v-if="subItem.content && item.type === 'worker'">
                工作内容：{{ subItem.content }}
              </div>
              <div class="online-resume-main-box-before-item-content-left-work" v-if="subItem.content && item.type === 'product'">
                项目内容：{{ subItem.content }}
              </div>
            </div>
            <div class="online-resume-main-box-before-item-content-right cursor" @click="confirmModal({ id: subItem.id, msg: '确认要删除么？',callback: item.type === 'worker' ? 'handleDelWorker' : 'handleDelProduct',
                },'取消',true)">
              <img src="../../../assets/images/delete.png" alt="">
            </div>
          </div>
        </div>
        <!-- 工作经历 end -->

        <!-- 教育经历 start -->
        <div class="online-resume-main-box-item" :class="{'borderTop' : index == 0}" v-for="(item,index) in educationList" :key="index">
          <div class="online-resume-main-box-item-left">
            <div class="online-resume-main-box-item-left-name">
              <span>{{ item.label }}</span>
              <span class="el-icon-edit-outline cursor" @click="operate(item.type,item)"></span>
            </div>
            <div class="online-resume-main-box-item-left-content" v-for="(subItem,subIndex) in item.children" :key="subIndex">
              <div v-if="item.type != 'school'">
                {{ subItem.value }}
              </div>
              <div v-else>
                {{ subItem.school }}　{{ subItem.education_text }}　{{ subItem.subject }}
              </div>
            </div>
          </div>
          <div class="online-resume-main-box-item-right" v-if="item.url">
            <img :src="item.url" alt="">
          </div>
        </div>
        <!-- 教育经历 end -->
        <!-- 获得证书 start -->
        <div class="online-resume-main-box-item certificate">
          <div class="online-resume-main-box-item-left">
            <div class="online-resume-main-box-item-left-name">
              <span>获得证书</span>
            </div>
            <div class="online-resume-main-box-item-left-content">
              <img :src="item.url" alt=""  v-for="(item,index) in certificateList" :key="index">
            </div>
          </div>
        </div>
        <!-- 获得证书 end -->
        <!-- 视频介绍 start -->
        <div class="online-resume-main-box-item certificate video">
          <div class="online-resume-main-box-item-left">
            <div class="online-resume-main-box-item-left-name">
              <span>视频介绍</span>
            </div>
            <div class="online-resume-main-box-item-left-content">
              <template v-if="videoList.length">
                <video :src="item" controls v-for="(item,index) in videoList" :key="index"></video>
              </template>

              <div class="empey-video cursor" v-else>
                <img src="../../../assets/images/add.png" alt="" @click="uploadAvImage()">
                <el-upload
                  class="display-none"
                  ref="upload-img"
                  :action="action"
                  :show-file-list="false"
                  :accept="'video/*'"
                  :on-success="(response, file)=>handleAvatarSuccess(response, file,)">
                </el-upload>
              </div>
            </div>
          </div>
        </div>
        <!-- 视频介绍 end -->
      </div>
    </div>
    <!-- 注销弹框 start -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="520px"
      class="custom-header"
      :before-close="handleClose">
      <div class="custom-header-box">
        <!-- 个人特长 start -->
        <div class="custom-header-box-content" v-if="dialogVisibleSpeciality || dialogVisibleTrain || dialogVisibleMyself">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="ruleForm.textareaVal">
          </el-input>
          <div class="custom-header-box-content-txt" v-if="dialogVisibleSpeciality">
            <span>0/300</span>
          </div>
          <div class="custom-header-box-content-txt" v-if="dialogVisibleTrain || dialogVisibleMyself">
            <span>{{ ruleForm.textareaVal.length }}/120</span>
          </div>
        </div>
        <!-- 个人特长 end -->

        <!-- 求职期望 start -->
        <div  class="custom-header-box-content hope" v-if="dialogVisibleHope">
          <div class="custom-header-box-content-item"
            v-for="(item) in hopeList"
            :key="item.value">
            <span class="custom-header-box-content-item-label">{{ item.label }}</span>
            <el-select class="custom-header-box-content-item-value" v-if="item.type === 'money'" v-model="item.value" placeholder="请选择">
              <el-option :label="subItem.label" :value="subItem.value"
                v-for="(subItem,subIndex) in item.option"
                :key="subIndex">
              </el-option>
            </el-select>
            <div class="position-work cursor" v-if="item.type === 'position'" @click="isShowSelectJobDialog=true">
              {{ post_name ? post_name : '请选择' }}
              <span class="el-icon-arrow-right"></span>
            </div>
            <div v-if="item.type === 'workCity'">
              <!-- 省市县组件 start -->
              <areaProvince ref="areaProvince" @handleChangeProp="handleChange"></areaProvince>
              <!-- 省市县组件 end -->
            </div>
          </div>
        </div>
        <!-- 求职期望 end -->

        <!-- 工作经历 start -->
        <div  class="custom-header-box-content hope worker" v-if="dialogVisibleWorker">
          <div class="custom-header-box-content-item">
            <div class="custom-header-box-content-item-label">公司名称</div>
            <el-input v-model="ruleForm.company_name"></el-input>
          </div>
          <div class="custom-header-box-content-item">
            <div class="custom-header-box-content-item-label">职位名称</div>
            <el-input v-model="ruleForm.position"></el-input>
          </div>
          <div class="custom-header-box-content-item">
            <div class="custom-header-box-content-item-label">在职时间</div>
            <div class="custom-header-box-content-item-value">
              <el-date-picker
                v-model="ruleForm.startTime"
                type="date"
                placeholder="开始日期">
              </el-date-picker>
              <span>至　</span>
              <el-date-picker
                v-model="ruleForm.endTime"
                type="date"
                placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <div class="custom-header-box-content-item-textarea">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.content">
            </el-input>
            <div class="custom-header-box-content-txt">
              <span>{{ ruleForm.content.length }}/120</span>
            </div>
          </div>
        </div>
        <!-- 工作经历 end -->

        <!-- 项目经历 start -->
        <div  class="custom-header-box-content hope worker" v-if="dialogVisibleProduct">
          <div class="custom-header-box-content-item">
            <div class="custom-header-box-content-item-label">项目名称</div>
            <el-input v-model="ruleForm.company_name"></el-input>
          </div>
          <div class="custom-header-box-content-item">
            <div class="custom-header-box-content-item-label">职位名称</div>
            <el-input v-model="ruleForm.position"></el-input>
          </div>
          <div class="custom-header-box-content-item-textarea">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.content">
            </el-input>
            <div class="custom-header-box-content-txt">
              <span>{{ ruleForm.content.length }}/120</span>
            </div>
          </div>
        </div>
        <!-- 项目经历 end -->

        <!-- 教育经历 start -->
        <div  class="custom-header-box-content hope worker" v-if="dialogVisibleEducation">
          <div class="custom-header-box-content-item">
            <div class="custom-header-box-content-item-label">学校名称</div>
            <el-input v-model="ruleForm.school"></el-input>
          </div>
          <div class="custom-header-box-content-item">
            <div class="custom-header-box-content-item-label">学历</div>
            <el-select v-model="ruleForm.education" placeholder="请选择">
              <el-option
                v-for="item in educationArr"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="custom-header-box-content-item">
            <div class="custom-header-box-content-item-label">专业名称</div>
            <el-input v-model="ruleForm.subject"></el-input>
          </div>
        </div>
        <!-- 教育经历 end -->
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="btn-red" @click="operate('sure')">确定</el-button>
      </div>
    </el-dialog>
    <!-- 注销弹框 end -->
    <selectJobDialog v-if="isShowSelectJobDialog" @closed="isShowSelectJobDialog=false" @confirm="selectedJob"></selectJobDialog>
  </div>
</template>

<script>
import { resumeRead, userInfo, companyGetConfig, resumeIndex, resumeAddProjectExperience, resumeAddEducationalExperience,
  resumeAddWorkExperience, deleteProjectExperience, deleteWorkExperience  } from '@/api/userInfo'
import selectJobDialog from '@/components/selectJobDialog.vue';
import moment from 'moment'
import baseMixin from "../../../mixins/base"
import areaProvince from '@/components/area.vue'
export default {
  name: 'onlineResume',
  mixins: [baseMixin],
  components: {
    selectJobDialog,
    areaProvince
  },

  data() {
    return {
      action: 'https://app.digginggoldroad.com/api/common/upload',
      messageList: [{
        label: '',
        value: '',
        type: 'name',
        url: require('@/assets/images/home-banner.png')
      }, {
        label: '求职状态',
        value: '',
        type: 'status'
      }, {
        label: '个人特长',
        value: '',
        type: 'speciality'
      }, {
        label: '求职期望',
        value: '',
        type: 'hope'
      }],
      workerList: [{
        title: '工作经历',
        type: 'worker',
        children: []
      },{
        title: '项目经历',
        type: 'product',
        children: []
      }],
      educationList: [{
        label: '教育经历',
        type: 'school',
        children: []
      },{
        label: '培训经历',
        type: 'train',
        children: [{
          value: '',
        }]
      },{
        label: '自我描述',
        type: 'self',
        children: [{
          value: '',
          grade: '',
          speciality: '',
        }]
      }],
      certificateList: [],
      videoList: [],
      dialogVisible: false,
      dialogVisibleSpeciality: false, // 个人特长
      dialogVisibleTrain: false, // 培训经历
      dialogVisibleHope: false, // 求职期望
      dialogVisibleWorker: false, // 工作经历
      dialogVisibleProduct: false, // 项目经历
      dialogVisibleEducation: false, // 教育经历
      dialogVisibleMyself: false, // 自我描述
      title: '',
      ruleForm: {
        textareaVal: '',
        school:'', // 学校名称
        education: '', // 学历
        subject: '', // 专业
        company_name: '', // 项目名称
        content: '', // 项目内容
        position: '', // 职位
        service_time: '',
        startTime: '', // 开始时间
        endTime: '', // 结束时间
      },
      hopeList: [{
        label: '期望职位选择',
        value: '',
        type: 'position',
      },{
        label: '期望薪资',
        value: '',
        type: 'money',
        option: []
      },{
        label: '工作城市',
        value: '',
        option: [],
        type: 'workCity'
      }],
      imgeCDN: this.$store.getters.imgeCDN,
      userinfo: {}, // 基本信息
      post_id: '', // 职位id
      salary_id: '', // 薪资id
      work_city: '', // 所在城市序号
      post_name: '', // 职位名称
      work_city_name: '', // 所在城市
      salary_name: '', // 新增名称
      educationArr: [], // 学历列表
      isShowFilterJobDialog: false,
      jobs: [],
      isShowSelectJobDialog: false,
      introduction_file: '', // 视频字段
    }
  },
  created () {
    this.getUserInfo()
    this.getResumeRead()
    this.companyGetConfigList()
  },
  methods: {
    back() {
      this.$router.back()
    },
    uploadAvImage() {
      this.$refs['upload-img'].$refs['upload-inner'].handleClick()
    },
    handleAvatarSuccess(res, file) {
      this.videoList.push(res.data.fullurl ? res.data.fullurl : '')
      this.introduction_file = `${res.data.url}`
    },
    /**
     * @Author: angel~dongsanling
     * @description: 选择城市
     * @param {*} data
     */
    handleChange(data){
      this.work_city = data.length && data.length > 1 ? data[1] : data[0]
    },
    /**
     * @Author: angel~dongsanling
     * @description: 选择职位回调
     */
    // 选择职位回调
    selectedJob(parent, child) {
      this.post_name = child.name
      this.post_id = child.id
      this.isShowSelectJobDialog = false
      this.hopeList[0].value = child.id
    },
    /**
     * @Author: angel~dongsanling
     * @description: 获取学历列表
     */
    companyGetConfigList(){
      this.educationArr = []
      companyGetConfig({type: 'all'}).then(res => {
        if (res.code == 1){
          let data = res.data
          for (const key in data.education) {
            this.educationArr.push({
              label: data.education[key],
              value: key
            })
          }
          for (const key in data.salary) {
            this.hopeList[1].option.push({
              label: data.salary[key],
              value: key
            })
          }
        }
      })


    },
    /**
     * @Author: angel~dongsanling
     * @description: 用户信息
     */
    getUserInfo () {
      userInfo().then(res => {
        if (res.code == 1){
          let data = res.data.userinfo
          this.messageList[0].label = data.nickname ? data.nickname: ''
          this.messageList[0].value = ` ${data.work_time_text}年工作经验/${data.education_text}/${data.age_text}岁`
          this.messageList[0].url = `${this.imgeCDN}${data.avatar}`
          this.messageList[1].value = `${data.job_status_text}`
          this.userinfo = data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 在线简历
     */
    getResumeRead () {
      this.certificateList = []
      this.videoList = []
      resumeRead().then(res => {
        if (res.code == 1) {
          let data = res.data
          this.messageList[2].value = data.speciality ? data.speciality : ''
          this.messageList[3].value = `${data.post_name ? data.post_name : ''}　${data.salary_name ? data.salary_name : ''}　${data.area.mergename && data.area.mergename.split(',') && data.area.mergename.split(',').length > 1 ? data.area.mergename.split(',')[1] : data.area.mergename.split(',')[0] }`
          this.post_name = data.post_name ? data.post_name : ''
          if (data.introduction_file) {
            this.videoList.push(`${this.imgeCDN}${data.introduction_file}`)
          } else {
            this.videoList = []
          }
          this.introduction_file = data.introduction_file
          this.salary_name = data.salary_name ? data.salary_name : ''
          this.work_city_name = data.area.mergename && data.area.mergename.split(',')[1]
          this.workerList[0].children = data.work_experiences.length ? data.work_experiences : []
          this.workerList[1].children = data.project_experiences.length ? data.project_experiences : []
          this.educationList[0].children = data.educational_experiences ? data.educational_experiences : []
          this.educationList[1].children[0].value = data.training_experience ? data.training_experience : ''
          this.educationList[2].children[0].value = data.self_description ? data.self_description : ''
          if (data.certificate_images.length) {
            data.certificate_images.map((item,index) =>{
              if (item){
                this.certificateList.push({
                  url: `${this.imgeCDN}${item}`
                })
              }
            })
          }
          this.work_city = data.area.city
          this.post_id = data.post_id
          this.salary_id = data.salary_id
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 关闭弹框
     */
    handleClose () {
      this.dialogVisible = false
      this.dialogVisibleSpeciality = false // 个人特长
      this.dialogVisibleTrain = false // 培训经历
      this.dialogVisibleHope = false // 求职期望
      this.dialogVisibleWorker = false // 工作经历
      this.dialogVisibleProduct = false // 项目经历
      this.dialogVisibleEducation = false // 教育经历
      this.dialogVisibleMyself = false // 自我描述
    },
    submit () {
      this.post_id = this.hopeList[0].value
      this.salary_id = this.hopeList[1].value
      let params = {
        introduction_file: this.introduction_file,
        post_id: this.post_id,
        salary_id: this.salary_id,
        self_description: this.educationList[2].children[0].value,
        speciality: this.messageList[2].value,
        training_experience: this.educationList[1].children[0].value,
        work_city: this.work_city
      }
      resumeIndex(params).then(res => {
        if (res.code == 1) {
          this.handleClose()
          this.getResumeRead()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 项目经历保存
     */
    submitAddProjectExperience () {
      let params = {
        company_name: this.ruleForm.company_name,
        content: this.ruleForm.content,
        position: this.ruleForm.position,
        service_time: ''
      }
      resumeAddProjectExperience(params).then(res => {
        if (res.code == 1) {
          this.handleClose()
          this.getResumeRead()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 工作经历保存
     */
    submitAddWorkExperience () {
      let params = {
        company_name: this.ruleForm.company_name,
        content: this.ruleForm.content,
        position: this.ruleForm.position,
        service_time: moment(this.ruleForm.startTime).format('YYYY年MM月DD日') + '-' + moment(this.ruleForm.endTime).format('YYYY年MM月DD日')
      }
      resumeAddWorkExperience(params).then(res => {
        if (res.code == 1) {
          this.handleClose()
          this.getResumeRead()
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    /**
     * @Author: angel~dongsanling
     * @description: 教育经历保存
     */
    submitAddEducationalExperience () {
      let params = {
        education: this.ruleForm.education,
        school: this.ruleForm.school,
        subject: this.ruleForm.subject
      }
      resumeAddEducationalExperience(params).then(res => {
        if (res.code == 1) {
          this.handleClose()
          this.getResumeRead()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    /**
     * @Author: angel~dongsanling
     * @description: 删除单个项目经历
     * @param {*} data
     */
     confirmed(data) {
      if (data.callback === "handleDelWorker") {
        deleteWorkExperience({id: data.id}).then(res => {
          if (res.code == 1) {
            this.$message.success('删除成功')
            this.getResumeRead()
          } else {
            this.$message.error(res.msg)
          }
        })
      } else if (data.callback === "handleDelProduct") {
        deleteProjectExperience({id: data.id}).then(res => {
          if (res.code == 1) {
            this.$message.success('删除成功')
            this.getResumeRead()
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    /**
     * @Author: angel~dongsanling
     * @description: 操作
     * @param {*} type 根据类型判断是那个方法
     * @param {*} data 当前点击传的参数
     */
    operate (type, data) {
      switch (type) {
        case 'name': // 修改姓名
          this.$router.push({
            path: '/personalData'
          })
          break
        case 'status': // 求职状态
          this.$router.push({
            path: '/personalData'
          })
          break
        case 'speciality': // 个人特长
          this.handleClose()
          this.dialogVisible = true
          this.dialogVisibleSpeciality = true
          this.title = data.label
          break
        case 'hope': // 求职期望
          this.handleClose()
          this.dialogVisible = true
          this.dialogVisibleHope = true
          this.title = data.label
          break
        case 'worker': // 工作经历
          this.handleClose()
          this.dialogVisible = true
          this.dialogVisibleWorker = true
          this.title = data.title
          break
        case 'product': // 项目经历
          this.handleClose()
          this.dialogVisible = true
          this.dialogVisibleProduct = true
          this.title = data.title
          break
        case 'school': // 教育经历
          this.handleClose()
          this.dialogVisible = true
          this.dialogVisibleEducation = true
          this.title = data.title
          break
        case 'train': // 培训经历
          this.handleClose()
          this.dialogVisible = true
          this.dialogVisibleTrain = true
          this.title = data.label
          this.ruleForm.textareaVal = data.children[0].value
          break
        case 'self': // 自我描述
          this.handleClose()
          this.dialogVisible = true
          this.dialogVisibleMyself = true
          this.title = data.label
          break
        case 'cancel': // 取消
          break
        case 'sure': // 确定
          if (this.dialogVisibleSpeciality) { // 个人特长保存
            this.messageList[2].value = this.ruleForm.textareaVal
            this.submit()
          } else if (this.dialogVisibleHope) { // 求职期望
            // this.educationList[1].children[0].value = this.ruleForm.textareaVal
            this.submit()
          } else if (this.dialogVisibleTrain) { // 培训经历
            this.educationList[1].children[0].value = this.ruleForm.textareaVal
            this.submit()
          } else if (this.dialogVisibleMyself) { // 自我描述
            this.educationList[2].children[0].value = this.ruleForm.textareaVal
            this.submit()
          } else if (this.dialogVisibleEducation) { // 教育经历
            this.submitAddEducationalExperience()
          } else if (this.dialogVisibleProduct) { // 项目经历
            this.submitAddProjectExperience()
          } else if (this.dialogVisibleWorker) { // 工作经历
            this.submitAddWorkExperience()
          }
          break
      }
    }
  }
}
</script>

<style scoped lang="less">
.online-resume{
  background: #F7F7F7;
  .online-resume-main{
    padding: 10px 40px 40px;
    margin-top: 12px;
    background: #fff;
    box-sizing: border-box;
    .online-resume-main-box{
      .online-resume-main-box-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 0px;
        border-bottom: 1px solid #e8e8e8;
        &.borderTop{
          border-top: 1px solid #e8e8e8;
        }
        .online-resume-main-box-item-left{
          .online-resume-main-box-item-left-name{
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 20px;
            .el-icon-edit-outline{
              margin-left: 10px;
            }
          }
          .online-resume-main-box-item-left-content{
            font-size: 18px;
            color: #585858;
            margin-bottom: 5px;
          }
        }
        .online-resume-main-box-item-right{
          width: 80px;
          height: 80px;
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        &.certificate{
          .online-resume-main-box-item-left-content{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            img{
              width: 200px;
              height: 140px;
              margin-right: 30px;
            }
            .empey-video{
              width: 100px;
              height: 100px;
              background: #F7F7F7;
              border-radius: 7px 7px 7px 7px;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width: 22px;
                height: 22px;
                margin: 0px auto;
              }
            }
          }
          &.video{
            border-bottom: none;
          }
        }
      }
      .online-resume-main-box-before-item{
        .online-resume-main-box-before-item-name{
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          .online-resume-main-box-before-item-left{
            font-size: 22px;
            font-weight: 600;
          }
          .online-resume-main-box-before-item-right{
            width: 32px;
            height: 32px;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
        .online-resume-main-box-before-item-content{
          height: 190px;
          padding: 30px;
          border-radius: 13px 13px 13px 13px;
          border: 1px solid #E8E8E8;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .online-resume-main-box-before-item-content-left{
            flex: 1;
            .online-resume-main-box-before-item-content-left-name{
              margin-bottom: 16px;
              font-size: 22px;
              font-weight: 400;
            }
            .online-resume-main-box-before-item-content-left-job{
              margin-bottom: 16px;
              font-size: 18px;
              color: #585858;
            }
          }
          .online-resume-main-box-before-item-content-right{
            width: 32px;
            height: 32px;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .online-resume-main-box-education{
        padding: 30px 0px;
        border-top: 1px solid #E8E8E8;
        border-bottom: 1px solid #E8E8E8;
        .online-resume-main-box-education-name{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 22px;
          font-weight: 600;
        }
        .online-resume-main-box-education-content{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .online-resume-main-box-education-content-left{
            font-size: 18px;
            color: #585858;

          }
          .online-resume-main-box-education-content-right{
            width: 32px;
            height: 32px;
            margin-top: 20px;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .custom-header{
    .custom-header-box{
      .custom-header-box-content{
        position: relative;
        height: 150px;
        background: #F7F7F7;
        border-radius: 7px 7px 7px 7px;
        /deep/.el-textarea{
          height: 150px;
          background: #F7F7F7;
          .el-textarea__inner{
            height: 150px;
            font-size: 14px;
            background: #F7F7F7;
          }
        }
        .custom-header-box-content-txt{
          position: absolute;
          right: 13px;
          bottom: 13px;
        }
        &.hope{
          background: #fff;
          .custom-header-box-content-item{
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .custom-header-box-content-item-label{
              width: 130px;
              text-align: right;
              padding-right: 30px;
            }
            .custom-header{
              flex: 1;
              text-align: right;
            }
            .custom-header-box-content-item-value{
              flex: 1;
            }
          }
          &.worker{
            height: auto;
            .custom-header-box-content-item-textarea{
              height: 180px;
              /deep/.el-textarea{
                height: 180px;
                background: #F7F7F7;
                .el-textarea__inner{
                  height: 180px;
                  font-size: 14px;
                  background: #F7F7F7;
                }
              }
            }
            .custom-header-box-content-item{
              height: 90px;
              display: block;
              .el-select{
                width: 100%;
              }
              .custom-header-box-content-item-label{
                display: block;
                text-align: left;
                font-size: 13px;
                margin-bottom: 13px;
              }
              .custom-header-box-content-item-value{
                height: 58px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                /deep/.el-input__inner{
                  width: 100%;
                }
              }
            }
          }
        }

      }
    }
  }
}
</style>
